export const environment = {
  production: true,
  apiCustomRootUrl: 'https://api.bcipotterydirect.com/backend/',
  apiCustomServerRootUrl: 'https://api.bcipotterydirect.com/backend/',
  // apiCustomRootUrl: 'http://192.168.0.208:8000/backend/',
  apiCustomIpAddress: 'https://geolocation-db.com/json/',
  apiRebate: 'https://og.bcipotterys.com/Online/api/',
  customerUrl: 'https://bcipotterydirect.com',
  s3Acess: {
    accessKeyId: '',
    secretAccessKey: '',
    // region: 'us-west-2',
    // signatureVersion: 'v4'
  },
  // Cloudfront bucket.
  imageBucket: '',
  videoBucket: '',
  teamMemberFolder: 'party/',
  businessUnitFolder: 'party/',
  storeFolder: 'party/',
  taxonomyBanner: 'taxonomy/',
  taxonomyThumbnail: 'taxonomy/',
  taxonomyIcon: 'taxonomy/',
  productFolder: 'items/',
  docUrl: '',
  imageBaseUrl: 'https://d1if7r1bmiltgp.cloudfront.net/',
  videoUrl: '',
  firebaseAccess: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: ""
  },
  flipAccess: {
    accessToken: "",
    merchantId: "",
    merchantNameIdentifier: ""
  },
  stipePublicKey: "",
  AWS_S3_XML_FOLDER: "assets/xml",
  isDevServer: true
};
