<header id="content">
  <div class="top-gray-header">
    <div class="main-container">
      <ul>
        <li>
          <a href="javascript:void(0)"><i class="icon-call"></i> +1 800 845 3343</a>
        </li>
        <li>
          <a [href]="urlFor('how-to-order')">How to order</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="main-header">
    <div class="header-inner-sec">
      <a (click)="toggleMenu()" class="mobile-toggle pointer-button">
        <i class="icon-hambargar-mobile"></i>
      </a>
      <!-- <a (click)="backMenu()" class="back-arrow" style="display: none;">
          <i class="icon-nex"></i>
      </a> -->
      <a (click)="router.navigate(['']);" class="main-logo" style="cursor: pointer;">
        <img alt="" [src]="getAssetsImageUrl('logo-main.png','assets/images')">
      </a>
      <div class="header-heading" (click)="router.navigate(['']);" style="cursor: pointer;">
        <p>BCI POTTERY DIRECT</p>
        <span>BY BORDER CONCEPTS</span>
      </div>
      <div class="header-left-sec">
        <ul>
          <li>
            <a href="javascript:void(0)" class="search">
              <i class="icon-search" (click)="openSearchOption()"></i>
            </a>
            <!-- mega search -->
            <div class="mega-search" *ngIf="isMegaMenuVisible">
              <app-search (isMegaMenuVisible)="handleCloseEvent($event)" [searchingItem]="isMegaMenuVisible"></app-search>
            </div>
            <!-- mega search -->
          </li>
          <li>
            <a (click)="navigateToWishlist()" style="cursor: pointer; position: relative;">
              <i class="icon-line-heart"></i>
            </a>
          </li>
          <li class="icon-use-drop">
            <a (click)="showAccountMenu()" style="cursor: pointer;">
              <i class="icon-user"></i>
            </a>
            <div class="login-drop-box show" *ngIf="isshowAccMenu">
                <ul>
                    <li>
                      <a (click)="navigateToMyAccount();showAccountMenu()">
                        <i class="icon-user"></i>
                        Profile
                      </a>
                     </li>
                    <li>
                      <a (click)="orders()">
                        <i class="icon-cart"></i>
                        My Orders
                      </a>
                    </li>
                    <li>
                      <a (click)="onSignout()">
                        <i class="icon-logout"></i>
                        Logout
                      </a>
                    </li>
                </ul>
            </div>
          </li>
         <li>
            <a (click)="navigateToCart()" style="cursor: pointer; position: relative;">
              <i class="icon-cart"></i>
              <span *ngIf="isLoggedIn == true && cartCount" class="badge">{{cartCount}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="mega-menu">
      <div class="menu-tab-iner-wrap" (mouseleave)="selectedCollection = -1">
        <div class="menu-tab" *ngFor="let collection of megaMenuList; let i = index"
          [ngClass]="{'active': selectedCollection == i}" (mouseenter)="selectIndexOfcollection(i)">
          {{collection.merchandisehierarchygroupname}}</div>
        <!-- mega-menu-box -->
        <div class="mega-menu-box" [ngClass]="{'show': selectedCollection != -1}"
          (mouseleave)="selectedCollection = -1" (mouseenter)="selectedCollection = selectedCollectionIndex">
          <div class="mega-innerwrap">
            <div class="left-section">
              <ul>
                <li>Shop by Collections</li>
                <li *ngFor="let children of megaMenuList[selectedCollection]?.children; let ci = index">
                  <a href="javascript:void(0)" (click)="selectedChildren = ci;megamenuClick(children,null)"
                    [ngClass]="{'active': selectedChildren === ci,'show': selectedChildren != -1}" (mouseenter)="selectedChildren = ci" >
                    {{children?.merchandisehierarchygroupname}}
                  </a>
                </li>
              </ul>
              <ul *ngIf="megaMenuList[selectedCollection]?.children" (mouseleave)="selectedChildren = -1" [ngClass]="{'show': selectedChildren != -1}">
                <li>Shop by Categories</li>
                <li *ngFor="let child of megaMenuList[selectedCollection]?.children[selectedChildren]?.children"
                (mouseenter)="hoveredChild = child"
                (mouseleave)="hoveredChild = null">
                  <a (click)="megamenuClick(megaMenuList[selectedCollection]?.children[selectedChildren],child)">{{child?.merchandisehierarchygroupshortdescription}}</a>
                </li>
              </ul>
            </div>
            <div *ngIf="megaMenuList[selectedCollection]?.children && hoveredChild?.nav_image" class="right-image-block">
              <!-- New Image Section -->
              <img alt=""
                   [src]="getimageUrl(hoveredChild?.nav_image, 'taxonomy', null)">
            </div>
            <div *ngIf="megaMenuList[selectedCollection]?.children && megaMenuList[selectedCollection]?.children[selectedChildren]?.nav_image" class="right-image-block">
              <img alt=""
                [src]="getimageUrl(megaMenuList[selectedCollection]?.children[selectedChildren]?.nav_image, 'taxonomy', null)">
            </div>

          </div>
        </div>
        <!-- mega-menu-box -->
      </div>

    </div>
  </div>
</header>

<!-- Mobile Menu -->
<div id="toggle-menu" class="mobile-menu-wrap">

  <div class="menu-top-section">
      <a (click)="navigateToMyAccount()" class="account pointer-button">
          <i class="icon-user"></i>
          <span>Account</span>
      </a>

  </div>
  <div class="menu-bottom-section">
      <ul class="menu-mobil">
          <li *ngFor="let collection of megaMenuList; let i = index">
              <a (click)="openCollections(collection)">
                  <span>{{collection.merchandisehierarchygroupname}}</span>
                  <i class="icon-next"></i>
              </a>
          </li>
          <li>
              <a [href]="urlFor('how-to-order')">
                  <span>How to order</span>
                  <i class="icon-next"></i>
              </a>
          </li>
      </ul>
      <a href="#" class="bottom-phone-no">
          <i class="icon-call"></i>
          <span>+1 800 845 3343</span>
      </a>
  </div>
  <!-- secound-menu -->
  <div id="second-menu" class="mobile-secound-menu" >
      <div class="menu-top-section">
          <a class="top-main-menu">
              <i class="icon-nex pointer-button" (click)="backMenu('second-menu')"></i>
              <span>{{selectedMenuCollection?.merchandisehierarchygroupname}}</span>
          </a>
      </div>
      <div class="menu-bottom-section-secound">
          <ul>
              <li>
                <a>Shop by Collections</a>
              </li>
              <li *ngFor="let collection of selectedMenuCollection?.children; let ci = index">
                  <a (click)="megamenuClick(collection,null)">{{collection?.merchandisehierarchygroupname}}</a>
                  <ul class="third-stage">
                    <li *ngFor="let child of collection.children">
                      <a (click)="megamenuClick(collection,child)">{{child?.merchandisehierarchygroupshortdescription}}</a>
                    </li>
                  </ul>
              </li>
          </ul>
      </div>

  </div>
  <!-- secound-menu end -->
</div>
<div class="mobile-menu-gray-bg"></div>
<!-- Mobile Menu -->

<!-- mobile search -->
<div class="mobile-search" style="display: none;">
  <a href="#" class="top-sec">
      <i class="icon-nex"></i>

  </a>
  <div class="mobile-search-section">
      <div class="mega-search-innerwrap">
          <div class="input-outerbox">
              <a href="" class="search-con">
                  <i class="icon-search"></i>
              </a>
              <input type="text">
          </div>
          <div class="head-sec">
              <span>Products</span>
              <a href="#">See all <i class="icon-next"></i></a>
          </div>
          <div class="mega-search-content">


              <div class="search-row">
                  <div class="image-box">
                    <img alt="" [src]="getAssetsImageUrl('bci_noimage.png','assets/images')">
                  </div>
                  <div class="right-name-section">
                      <p>Ambergrove Round Planter</p>
                      <span>MD814285</span>
                  </div>
              </div>
              <div class="search-row">
                  <div class="image-box">
                    <img alt="" [src]="getAssetsImageUrl('bci_noimage.png','assets/images')">
                  </div>
                  <div class="right-name-section">
                      <p>Ambergrove Round Planter</p>
                      <span>MD814285</span>
                  </div>
              </div>

          </div>

      </div>
  </div>
</div>
<!-- mobile search -->
