import { HttpClient, HttpParams, HttpUrlEncodingCodec, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataApiService {
  constructor(readonly http: HttpClient, readonly router: Router, private route: ActivatedRoute) {

  }

  /**
   * Method to get cms page data based on page name.
   * @param cmsSlug CMS page name;
   * @returns Observable of httpclient.
   */
  getCmsPage(cmsSlug: string): Observable<any> {
    let params = new HttpParams();
    if (JSON.parse(localStorage.getItem('businessUnitId'))) {
      params = params.append('businessunitid', JSON.parse(localStorage.getItem('businessUnitId')));
      return this.http.get(`api/v1/allcmspages/${cmsSlug}`, { params });
    }
    else {
      return of({});
    }
  }
  getCmsPageWithOffset(cmsSlug: string, limit: number, offset: number): Observable<any> {
    let params = new HttpParams();
    if (JSON.parse(localStorage.getItem('businessUnitId'))) {
      params = params.append('businessunitid', JSON.parse(localStorage.getItem('businessUnitId')));
      params = params.append('offset', offset.toString());
      params = params.append('limit', limit.toString());
      return this.http.get(`api/v1/cmspagewithelements/${cmsSlug}`, { params });
    }
    else {
      return of({});
    }
  }
  /**
  * Method to get Category details Based on category ID.
  * @param catId Category Id;
  * @returns Observable of httpclient.
  */
  getCategoryDetails(catname: string): Observable<any> {
    let params = new HttpParams();
    if (JSON.parse(localStorage.getItem('businessUnitId')) && JSON.parse(localStorage.getItem('storeId'))) {
      params = params.append('businessunitid', JSON.parse(localStorage.getItem('businessUnitId')));
      params = params.append('storeid', JSON.parse(localStorage.getItem('storeId')));
      return this.http.get(`api/v1/category/${catname}`, { params });
    }
    else {
      return of([]);
    }
  }
  /**
   * Method to get data by id.
   * @param requestData Request data for api.
   * @returns Observable of httpclient.
   */
  getDataById(requestData: any): Observable<any> {
    if (requestData.tableId === 'allcmspages') {
      let params = new HttpParams();
      if (JSON.parse(localStorage.getItem('businessUnitId'))) {
        params = params.append('businessunitid', JSON.parse(localStorage.getItem('businessUnitId')));
        return this.http.get(`api/v1/${requestData.tableId}/${requestData.id}`, { params: params });
      }
      else {
        return of([]);
      }
    }
    return this.http.get(`api/v1/${requestData.tableId}/${requestData.id}`)
      .pipe(catchError(this.handleError));
  }

  /**
   * @returns Observable of httpclient.
   */
  getTaxonomyNavigation(functionId?: any) {
    if (functionId) {
      return this.http.get(`api/v1/taxonomynavigation/?function_id=${functionId}`);
    } else {
      return this.http.get(`api/v1/taxonomynavigation/`);
    }
  }

  getTaxonomyLevelWiseNavigation(functionId?: any, parentId?: any) {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    if (functionId) {
      if (parentId)
        return this.http.get(`api/v1/taxonomylevelwiselist/?function_id=${functionId}&store_id=${storeId}&parent_id=${parentId}`);
      else
        return this.http.get(`api/v1/taxonomylevelwiselist/?function_id=${functionId}&store_id=${storeId}`);
    } else {
      return this.http.get(`api/v1/taxonomylevelwiselist/`);
    }
  }

  getTaxonomyLevelWithSlug(slug: string) {
    return this.http.get(`api/v1/taxonomyparantlist/?taxonomy_id=${slug}`);
  }

  getNavFooter() {
    if (JSON.parse(localStorage.getItem('businessUnitId'))) {
      const businessunitid = JSON.parse(localStorage.getItem('businessUnitId'));
      return this.http.get(`api/v1/navpublicfooter/?businessunitid=${businessunitid}`);
    }
  }

  getTaxonmyText(id: any) {
    return this.http.get(`api/v1/taxonomyadditionaldetails/${id}`);
  }

  /**
   * @returns Observable of httpclient.
   */
  getNavigationItem() {
    if (JSON.parse(localStorage.getItem('businessUnitId'))) {
      const businessunitid = JSON.parse(localStorage.getItem('businessUnitId'));
      const storeId: any = JSON.parse(localStorage.getItem('storeId'));
      return this.http.get(`api/v1/navitem/?businessunitid=${businessunitid}&is_default=true&storeid=${storeId}`);
    }
    else {
      return of([]);
    }
  }

  getProductList(limit: any, offset: any, params: any) {
    return this.http.get(`api/v1/products/?limit=${limit}&offset=${offset}&search=`);
  }

  getFilterList(params: any) {
    return this.http.post(`api/v1/products/`, params);
  }

  getGlobalFilterList() {
    return this.http.get(`api/v1/filtermenus/?functionid=${4}`);
  }

  getProductListSearch(limit: any, offset: any, searchText: any) {
    return this.http.get(`api/v1/products/?limit=${limit}&offset=${offset}&search=${searchText}`);
  }

  getProductListFilter(filterobject: any) {
    return this.http.post(`api/v1/products/`, filterobject);
  }


  getProductBySKU(sku: any) {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    return this.http.get(`api/v1/products/?store=${storeId}&search=${sku}`);
  }

  getProductBySlug(slug: any) {
    return this.http.get(`api/v1/products1/${slug}`);
  }

  getProductSpecificationBySlug(slug: any) {
    return this.http.post(`api/v1/productspecification/${slug}`, {});
  }

  getProductListforCMS(slug: string, filterData: any, itemId: number, getParams?: any) {
    if (filterData) {
      return this.http.post(`api/v1/productsgroupforcms/`, filterData);
    }
  }
  getLandingPageSlug(cmspagesIds: any) {
    return this.http.get(`api/v1/landingpageslug/?cmspagesIds=${cmspagesIds}`);
  }

  getRelatedItems(itemStr?: any): Observable<any> {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    return this.http.post(`api/v1/getrelateditems/`, { "itemids": itemStr, store: storeId });
  }

  getBrandList(itemId?: number) {
    if (itemId) {
      return this.http.get(`api/v1/brands/${itemId}`);
    } else {
      return this.http.get(`api/v1/brands/`);
    }
  }

  getSubBrandList(itemId: number) {
    return this.http.get(`api/v1/brands/?parent=${itemId}`);
  }

  getAllCustomFieldList() {
    return this.http.get(`api/v1/customfields`);
  }

  getListingData(requestData: any): Observable<any> {
    let params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    if (requestData.tableId === 'todaysdeal') {
      params = params.append('storeid', requestData.storeid);
      params = params.append('date', requestData.date);
      return this.http.get(`api/v1/todaysdeal/`, { params });
    }
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    if (requestData.tableId === 'recentvieweditem') {
      const isLoggedin = requestData.isLoggedin;
      if (isLoggedin) {
        return this.http.get(`api/v1/recentvieweditem/?store=${storeId}`);
      }
      else {
        const recentViewedItems = localStorage.getItem('recentViewedItems');
        params = params.append('items', recentViewedItems);
        params = params.append('store', storeId);
        return this.http.get(`api/v1/itemswithoutlogin/`, { params });
      }
    }



    if (requestData.filter) {
      params = params.append('status', requestData.filter.status);
      params = params.append('search', requestData.filter.search);
    }

    if (requestData.paginator) {
      params = params.append('limit', requestData.paginator.limit);
      params = params.append('offset', requestData.paginator.offset);
    }

    if (requestData.storeunitid) {
      params = params.append('businessunitid', requestData.storeunitid);
    }

    return this.http.get(`api/v1/${requestData.tableId}/`, { params });

  }

  getAllData(requestData: any): Observable<any> {

    /*Example[ requestData : {tableId: 'apiurl', id:'particularId', params: {limit: 20, offset: 0, status: 'Active'}} ]*/

    let params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });

    if (requestData.params !== undefined && Object.keys(requestData.params).length > 0) {
      const keys: Array<any> = Object.keys(requestData.params);
      const values: Array<any> = Object.values(requestData.params);
      keys.forEach((item: any, index: any) => {
        params = params.append(item, values[index]);
      });
    }
    let url = `api/v1/${requestData.tableId}/`;
    if (requestData.id !== undefined) {
      url += requestData.id;
    }
    return this.http.get(url, { params })
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse): any {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof HttpErrorResponse) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return of([]);
  }

  /**
    * Method to post login Based on username and password.
    * @param requestData object of key value;
    * @returns Observable of httpclient.
    */
  postLogin(requestData: any): Observable<any> {

    return this.http.post(`api/v1/consumerlogin/`, requestData);

  }
  /**
    * Method to post register Based on user parameter.
    * @param requestData object of key value;
    * @returns Observable of httpclient.
    */
  postRegister(requestData: any): Observable<any> {

    return this.http.post(`api/v1/consumerregister/`, requestData);

  }
  /**
    * Method to post forgotpassword Based on user email/mobilenumber.
    * @param requestData object of key value;
    * @returns Observable of httpclient.
    */
  postForgotpassword(requestData: any): Observable<any> {

    return this.http.post(`api/v1/consumerforgotpassword/`, requestData);

  }
  /**
     * Method to post forgotpassword Based on user email/mobilenumber.
     * @param requestData object of key value;
     * @returns Observable of httpclient.
     */
  postResetpassword(requestData: any): Observable<any> {
    return this.http.post(`api/v1/consumerforgotpassword/`, requestData);
  }

  //putAccountsettings
  /**
      * Method to put account settings Based on user ID.
      * @param userid object of key value;
      * @param requestData object of key value;
      * @returns Observable of httpclient.
      */
  //  putAccountSettings( requestData: any): Observable<any> {
  //   return this.http.put(`api/v1/consumerprofile/51`, requestData);
  //  }
  putAccountSettings(userId: any, requestData: any): Observable<any> {
    return this.http.put(`api/v1/consumerprofile/${userId}`, requestData);
  }

  //postAdressbook
  /**
  * Method to post forgotpassword Based on user email/mobilenumber.
  * @param requestData object of key value;
  * @returns Observable of httpclient.
  */
  postAdressbook(userId: any, requestData: any): Observable<any> {
    return this.http.post(`api/v1/customeraddresses/${userId}`, requestData);
  }

  general_enquiry_postData(postData: any): Observable<any>{
    return this.http.post(`api/v1/generalenquiries/`, postData);
  }
  //getAddress
  //  /**
  //      * Method to get list Based on user ID.
  //      * @param requestData object of key value;
  //      * @returns Observable of httpclient.
  //      */
  getAddress(userid: any): Observable<any> {
    return this.http.get(`api/v1/customeraddresses/${userid}`);
  }

  getEditAddress(partyId: any, itemid: any): Observable<any> {
    return this.http.get(`api/v1/customeraddresses/${partyId}/?address_id=${itemid}`)
  }
  // /backend/api/v1/customeraddresses/51/?address_id=241


  //deleteAddress
  deleteAddress(itemid: any): Observable<any> {
    let partyId = localStorage.getItem('userId')
    return this.http.delete(`api/v1/customeraddresses/${partyId}?address_id=${itemid}`);
  }

updateAddress(partyId: any,itemid:any, requestData: any): Observable<any>{
  return this.http.put(`api/v1/customeraddresses/${partyId}?address_id=${itemid}`, requestData)
}
  /**
      * Method to post forgotpassword Based on user email/mobilenumber.
      * @param requestData object of key value;
      * @returns Observable of httpclient.
      */
  postSetnewpassword(requestData: any): Observable<any> {

    return this.http.post(`api/v1/consumerforgotpassword/`, requestData);

  }
  checkForgotPasswordLink(linkToken: any): Observable<any> {
    return this.http.post(`api/v1/passwordresetpagevalidation/`, { "token": linkToken });
  }
  /**
       * Method to get my profile Based on user ID.
       * @param requestData object of key value;
       * @returns Observable of httpclient.
       */
  getmyprofile(userid: any): Observable<any> {

    return this.http.get(`api/v1/consumerprofile/${userid}`);

  }
  /**
       * Method to put My profile Based on user ID.
       * @param userid object of key value;
       * @param requestData object of key value;
       * @returns Observable of httpclient.
       */
  putmyprofile(userid: any, requestData: any): Observable<any> {

    return this.http.put(`api/v1/consumerprofile/${userid}`, requestData);

  }

  /**
   * Recent Prodct View Post
   */
  getRecentProductList() {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    return this.http.get(`api/v1/recentvieweditem/?store=${storeId}&limit=4`);
  }

  postRecentProduct(obj:any) {
    return this.http.post(`api/v1/recentvieweditem/`,obj);
  }

  getCustomerLikeProductList(slug) {
    return this.http.get(`api/v1/customerlikeitemlist/?itemslug=${slug}`);
  }

  /**
   * Feature List
   */

  getfeatureList(slug) {
    return this.http.get(`api/v1/productsfeatures/${slug}`);
  }

  /**
   *
   * @param url for redirect to that route
   */
  reloadCurrentRoute(url?: any) {
    let currentUrl = url ? url : this.router.url;
    let params = {};
    params = this.route.snapshot.queryParams;
    if (!Object.keys(params).length && window.location.href.includes('?')) {
      let hrefParams = {}
      let paramValue = window.location.href.split('?')[1];
      for (let keyValue of paramValue.split('&')) {
        keyValue.split('=')[0] != 'qParam' && (hrefParams[keyValue.split('=')[0]] = keyValue.split('=')[1]);
      }
      params = hrefParams;
    }

    this.router
      .navigateByUrl('/shop', { skipLocationChange: true })
      .then(() => {
        if (url) {
          this.router.navigate([currentUrl]);
        } else {
          if (params['store_no']) {
            this.router.navigate([(window?.location.pathname)]);
          } else {
            this.router.navigate([(window?.location.pathname)], { queryParams: params });
          }
        }
      });
  }

  /**
   * Method to post review ratings for a particular product.
   * @param requestData object of key value;
   * @returns Observable of httpclient.
   */
  postProductReviewsRatings(requestData: any): Observable<any> {
    return this.http.post(`api/v1/productreviewsratings/`, requestData);
  }

  /**
   * Method to put review ratings for a particular product on review id.
   * @param review_id object of key value;
   * @param requestData object of key value;
   * @returns Observable of httpclient.
   */
  productReviewsRatingsUpdate(review_id: any, requestData: any): Observable<any> {
    return this.http.put(`api/v1/productreviewsratingsupdate/${review_id}`, requestData);
  }

  /**
   * Method to post question for a partitular product.
   * @param requestData object of key value;
   * @returns Observable of httpclient.
   */
  postQuestion(requestData: any): Observable<any> {
    return this.http.post(`api/v1/productquestion/`, requestData);
  }
  getCommonDropDowndata(objectIds: []): Observable<any[]> {
    const responseArray: any[] = [];
    objectIds.forEach(req => {

      responseArray.push(this.http.get(`api/v1/${req}/`)
        // .pipe(catchError(this.handleError)));
      );

    }
    );
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin(responseArray);
  }
  getStateByCountry(countryId: any): Observable<any> {
    return this.http.get(`api/v1/countrybystate/${countryId}`);
  }
  getCountry(): Observable<any> {
    return this.http.get(`api/v1/country/`);
  }

  wishListData(wishItemIds?: number, active?: boolean): Observable<any> {
    if (wishItemIds) {
      if (active) {
        return this.http.delete(`api/v1/mywishlist/?ids=${wishItemIds}`);
      } else {
        return this.http.put(`api/v1/mywishlist/`, wishItemIds);
      }
    } else {
      return this.http.get(`api/v1/mywishlist/`);
    }
  }

  deleteWishlist(item_identifier: any, positemqulifier_id): Observable<any> {
    return this.http.delete(`api/v1/mywishlist/?item_identifier=${item_identifier}&positemqulifier_id=${positemqulifier_id}`)
  }





  getCartDetails(customerOrderId?: any) {
    let booking_flag = JSON.parse(localStorage.getItem('booking_flag'));
    let store;
    if (!booking_flag) {
      const storeData = localStorage.getItem('storeId');
      store = this.isValidJSON(storeData) ? JSON.parse(storeData) : null;
    } else {
      const bookingStoreData = localStorage.getItem('booking_store');
      store = this.isValidJSON(bookingStoreData) ? JSON.parse(bookingStoreData) : null;
    }
    return this.http.get(`api/v1/cart/?storeid=${store}`);
  }
  isValidJSON(data: string | null): boolean {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  }

  getLocalCartItems(itemStr?: any, businessUnitId?: number): Observable<any> {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    return this.http.get(`api/v1/products/?item_ids=${itemStr}&store=${storeId}&limit=100`);
  }

  getCartList(): Observable<any> {
    return this.http.get(`api/v1/cartlistidentifier/`);
  }

  getSearch(url: string): Observable<any> {
    return this.http.get(`${url}`);
  }
  /**
   * Method to get Navigation URLs
  **/
  getNavigationURL() {
    return this.http.get(`api/v1/navigationurl/`);
  }

  addToCart(cartObj: any): Observable<any> {
    return this.http.post(`api/v1/cart/`, cartObj);
  }

  deleteCart(customerorderlineitemid: number, customerOrderId: number): Observable<any> {
    return this.http.delete(`api/v1/cart/${customerorderlineitemid}`);
  }

  getCustomWishlist(customerid: number): Observable<any> {
    let customerId: any = JSON.parse(localStorage.getItem('customer_id'));

    return this.http.get(`api/v1/consumerwishlist/${customerId}`)
  }

  myAddressGenerate(userId: number, object?: any) {
    if (object) {
      return this.http.post(`api/v1/customeraddresses/${userId}`, object);
    } else {
      return this.http.get(`api/v1/customeraddresses/${userId}`)
    }
  }
  myAddressAction(userId: number, object: any, type: string) {
    if (type == 'edit') {
      return this.http.put(`api/v1/customeraddresses/${userId}?address_id=${object.id}`, object);
    } else if (type == 'delete') {
      return this.http.delete(`api/v1/customeraddresses/${userId}?address_id=${object.id}`);
    }
  }

  myOrderListFetch(customer_id: number, offset?: number, limit?: number, startDate?: string, endDate?: string, orderState?: any, searchValue?: string) {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    if (offset >= 0 && limit) {
      return this.http.get(`api/v1/customerorderlist/${customer_id}?storeid=${storeId}&offset=${offset}&limit=${limit}&startdate=${startDate}&enddate=${endDate}&order_state=${orderState}&search=${searchValue}`);
    } else {
      return this.http.get(`api/v1/customerorderlist/${customer_id}?storeid=${storeId}`);
    }
  }
  getCustomerBillingAddressOrderSummary(customerId: number) {
    return this.http.get(`api/v1/customerbillingaddressordersummary/${customerId}`);
  }

  submitCmsForm(body: any) {
    return this.http.post(`api/v1/customermailsendfromcms/`, body);
  }
  saveCmsFormData(body: any) {
    return this.http.post(`api/v1/cmsformuserinput/`, body);
  }

  checkCuponValidation(code: any) {
    let storeId: any = JSON.parse(localStorage.getItem('storeId'));
    code.storeid = storeId;
    return this.http.post(`api/v1/cuponvalidation/?storeid=${storeId}`, code);
  }

  orderPickupAuthorize(obj?: any, customer_id?: number) {
    if (customer_id) {
      return this.http.get(`api/v1/orderpickupauthorize/?customer_id=${customer_id}`);
    } else {
      return this.http.post(`api/v1/orderpickupauthorize/`, obj);
    }
  }
  stripePayment(stripeObj: any) {
    return this.http.post(`api/v1/stripepayment/`, stripeObj);
  }
  getStripeCardList() {
    return this.http.get(`api/v1/stripecardlist/`);
  }
  saveBillingAddress(data: any, type?: string) {
    if (type && type == 'giftcard') return this.http.post(`api/v1/customergiftcard/billingaddresssave/`, data);
    else return this.http.post(`api/v1/billingaddresssave/`, data);
  }
  checkPaymentMethod(key: string) {
    return this.http.get(`api/v1/checkpaymentmethod/?key=${key}`);
  }

  stripePaymentAuthorized(obj: any) {
    return this.http.post(`api/v1/stripepaymentauthorized/`, obj);
  }
  giftCardPaymentCapture(obj: any) {
    return this.http.post(`api/v1/giftcardpaymentcapture/`, obj)
  }

  claimRebate(url: any, type: string, obj: any, urlType: string) {
    if (urlType === 'New') return this.http.post(`api/v1/rebatecenter/`, obj, { observe: 'response' });
    else return this.http.post(`${url}/${type}`, obj, { observe: 'response' });
  }
  saveclaimedRebate(obj: any) {
    return this.http.post(`api/v1/rebatecentervalidate/`, obj);
  }
  getRebateList(url: any, type: string, obj: any) {
    return this.http.post(`${url}/${type}`, obj);
  }
  getRebateAllList(url: any, email: string) {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get(`${url}`, { params });
  }
  getActiveEbateUrl() {
    return this.http.get(`api/v1/customerrebate/`);
  }

  getSeoDetails(cmsSlugKey: string, cmsSlug: string, storeId?: string) {
    const headers = new HttpHeaders().set('Cache-Control', 'no-cache'); // Disable caching
    const options = { headers: headers, force: true };
    if (storeId) {
      return this.http.get(`api/v1/seoendpoint/?${cmsSlugKey}=${cmsSlug}&storeid=${storeId}`);
    } else {
      return this.http.get(`api/v1/seoendpoint/?${cmsSlugKey}=${cmsSlug}`);
    }
  }



  getSitemapDetails(pageName: string, slugData: any, productData?: any) {
    if (productData && (Object.keys(productData).length > 0)) {
      return this.http.post(`api/v1/sitemap/?pagename=${pageName}&slugname=${slugData}`, productData);
    } else {
      return this.http.get(`api/v1/sitemap/?pagename=${pageName}&slugname=${slugData}`);
    }
  }

  createxmlsitemap(url: string, param?: any) {
    return this.http.post(url, param);
  }

  getExistSitemap(url: string, filename: string) {
    return this.http.get(`${url}?file_name=${filename}`);
  }


  checkItemsInStock(itemsArr: any, booking_store?: number) {
    let booking_flag = (booking_store || localStorage.getItem('booking_flag') == 'true' && booking_store !== 0) ? true : false;
    let store;
    if (!booking_flag) {
      const storeData = localStorage.getItem('storeId');
      store = this.isValidJSON(storeData) ? JSON.parse(storeData) : null;
    } else {
      const bookingStoreData: any = booking_store || localStorage.getItem('booking_store');
      store = this.isValidJSON(bookingStoreData) ? JSON.parse(bookingStoreData) : null;
    }
    return this.http.get(`api/v1/itemstockcheck/?${itemsArr}&store=${store}`)
  }

  getGlobalScript() {
    const businessunitid = JSON.parse(localStorage.getItem('businessUnitId'));
    return this.http.get(`api/v1/globalsettingscript/${businessunitid}`);
  }
  getProductConversionRate(productId: any) {
    const productId_ = productId ?? '';
    return this.http.get(`api/v1/productqulifierconversion/${productId_}`);
  }
  getStockChecksForAllStores(productId: any, qualifierId: any) {
    return this.http.get(`api/v1/itemstockcheckforstores/?items=${productId},${qualifierId}`);
  }
  getCustomerStockCheck(data?: any) {
    if (data) return this.http.put(`api/v1/customerstockcheck/`, data);
    else return this.http.get(`api/v1/customerstockcheck/`);
  }

  getOrderHistoryList(requestData: any) {
    return this.http.post(`services/admin/oneid/v1/online/order`, requestData);
  }

  getorderHistoryDetails(requestData: any) {
    return this.http.post(`services/admin/oneid/v1/online/order-lines`, requestData)
  }

  getorderPImage(url: any) {
    return this.http.get(url);
  }

  saveGiftcards(data?: any) {
    if (data) return this.http.post(`api/v1/customergiftcard/`, data);
  }
  getGiftcards(customerId?: any) {
    if (customerId) return this.http.get(`api/v1/customergiftcard/${customerId}`);
  }
  deleteGiftcard(data: any) {
    return this.http.delete(`api/v1/customergiftcard/?giftcard_id=${data.giftcard}&customer_id=${data.customer_id}`, data);
  }
  saveGiftcardAddress(data: any) {
    if (data) return this.http.post(`api/v1/customergiftcard/shippingdetails/`, data);
  }
  getGiftcardOrders(customerId?: any) {
    if (customerId) return this.http.get(`api/v1/giftcardorders/${customerId}`);
  }
  deleteCard(key: string) {
    if (key) return this.http.delete(`api/v1/stripecardlist/?card_id=${key}`);
  }
  getSliderDataByIds(requestIDs: any): Observable<any> {
    return this.http.get(`api/v1/cmslider/?ids=${requestIDs}`);
  }
  /**
   * changeCartStatus() method will change the order status from processing to create if payment failed
   * @param data
   */
  changeCartStatus(data: any) {
    if (data) return this.http.post(`api/v1/stripepaymentfailed/`, data);
  }
  /**
   * This function is responsible for restricting the user's cart functionality during the payment process. It communicates with the API to enforce the necessary restrictions to ensure a smooth payment experience.
   * @param data
   * @returns
   */
  restrictCartDuringPayment(data: any) {
    if (data) return this.http.post(`api/v1/checkout/`, data);
  }

  getImageData(data: any): Observable<any> {
    if (data) return this.http.post(`api/v1/imageinfobyname/`, data);
  }
  getOrderStatus() {
    return this.http.get(`api/v1/uiorderstatelookup/`);
  }

  getBookingLists(id?: number, offset?: number, limit?: number) {
    if (id) return this.http.get(`api/v1/bookingevent/${id}/?offset=${offset}&limit=${limit}`);
    else return this.http.get(`api/v1/bookuilist/?offset=${offset}&limit=${limit}`);
  }
  getCurrentOrderPickupWindow(eventId: number, pickupWindowId: number): Observable<any> {
    return this.http.get(`api/v1/pickupslotcheck/${eventId}/${pickupWindowId}`);
  }
  getPickupWindows(event_id: number): Observable<any> {
    return this.http.get(`api/v1/getpickupwindows/${event_id}`);
  }
  getEmailState() {
    return this.http.get(`api/v1/customernightlyemail/`);
  }
  putEmailState(custId: number, payload: any) {
    return this.http.put(`api/v1/customernightlyemail/${custId}`, payload);
  }

  getCartContainerProducts(container_settings_id?: number,  switched?: boolean) {
    let cartUrl = container_settings_id ? `api/v1/cart/?container_settings_id=${container_settings_id}&switched=${switched}` : `api/v1/cart/`
    return this.http.get(cartUrl);
  }

  getOrderProducts(order_Id?: number, old_order?:any) {
    let orderProductUrl = `api/v1/orderedit/${order_Id}?old_order=${old_order}`
    return this.http.get(orderProductUrl);
  }

  getCustomerCartCount() {
    return this.http.get(`api/v1/customercartcount/`);
  }

  getSavedContainerList() {
    return this.http.get(`api/v1/customerconatinersettingscart/`);
  }

  getContainerDetails(id?:any) {
    if(id){
      return this.http.get(`api/v1/userconatinersettings/${id}`);
    }else{
      return this.http.get(`api/v1/userconatinersettings/`);
    }

  }

  changeContainerDetails(id:any, obj:any) {
    return this.http.put(`api/v1/userconatinersettings/${id}`,obj);
  }

  deleteItemFromCart(container_settings_id: number, customerorderlineitemid: number) {
    return this.http.delete(`api/v1/cart/${container_settings_id}?customerorderlineitemid=${customerorderlineitemid}`);
  }

  deletePalletFromCart(container_settings_id: number, palletOrder: any, orderedit_delete:any) {
    orderedit_delete = orderedit_delete?1:0;
    return this.http.delete(`api/v1/cart/${container_settings_id}?pallet_order=${palletOrder}&orderedit_delete=${orderedit_delete}`);
  }

  deletePalletConfirmation(container_settings_id: number, palletOrder: string, orderedit_delete:any) {
    orderedit_delete = orderedit_delete?1:0;
    return this.http.post(`api/v1/checkpalletdelete/${container_settings_id}?pallet_order=${palletOrder}`,'');
  }

  saveCartContainer(containerId: number, containerName: string) {
    return this.http.put(`api/v1/customerconatinersettingscart/${containerId}`, { name: containerName });
  }

  clearCartOrder(container_settings_id: number){
    return this.http.delete(`api/v1/cart/${container_settings_id}`);
  }

  createNewContainer(data:any){
    return this.http.post(`api/v1/customerconatinersettingscart/`, data);
  }

  addColorInCart(requsestData: any){
    return this.http.post(`api/v1/changeorderquantity/`, requsestData);
  }

  saveRetailFreightValues(obj: any) {
    return this.http.post(`api/v1/freightretail/`, obj);
  }

  getRetailFreightValues(){
    return this.http.get(`api/v1/freightretail/`);
  }

  placeOrder(requestData:any){
    return this.http.post(`api/v1/customershippingdetails/`, requestData);
  }

  productQtyChange(requestData:any){
    return this.http.put(`api/v1/changeorderquantity/`, requestData);
  }

  getMyOrdersList() {
    return this.http.get(`api/v1/customershippingdetails/`);
  }

  cartCheckout(data:any){
    return this.http.post(`api/v1/checkout/`, data);
  }

  getOrderDetails(data: any){
    return this.http.post(`api/v1/customerorderdetails/`, data);
  }

  getProductInCart(pallet_type:any,item_identifier:any, specification_name:any, batchcolorabriviation:any){
    return this.http.get(`api/v1/orderspecification/?pallet_type=${pallet_type}&item_identifier=${item_identifier}&specification_name=${specification_name}&batchcolorabbreviation=${batchcolorabriviation}&customerorder_id=${localStorage.getItem('customerorder_id')}`);
  }
  copyPalletCart(orderId:any, palletOrder:any,pallet:any,usercontainerSettings_id?:number){
    let data={
      palletorder:palletOrder,
      sets_per_pallet: pallet.items[0].sets_per_pallet,
      pallet_space: pallet.items[0].space,
      orderedit_container_settings_id: usercontainerSettings_id
    }
    return this.http.put(`api/v1/copycart/${orderId}`,data);
  }
}
