import { Component,Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
  selector: 'app-color-swatch-slider-dailog',
  standalone: true,
  imports: [CommonModule,MatButtonModule,CarouselModule,],
  templateUrl: './color-swatch-slider-dialog.component.html',
  styleUrls: ['./color-swatch-slider-dialog.component.scss']
})
export class ColorSwatchSliderDialogComponent implements OnInit {

  customSwatchSlider: OwlOptions = {
    loop: false,
    margin: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    navSpeed: 700,
    navText: [
      '<i class="material-icons">arrow_back_ios</i>',
      '<i class="material-icons">arrow_forward_ios</i>',
    ],

    responsive: {
      0: {
        items: 3,
        nav: true,
      },
      600: {
        items: 6,
        nav: true,
      },
      800: {
        items: 6,
        nav: true,
      },
      1200: {
        items: 6,
        nav: true,
      },
    },
  };

  constructor(
    private router : Router,
    public dialogRef: MatDialogRef<ColorSwatchSliderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    public utilityService: UtilityService,
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }
  closeDialog(): void {
    this.dialogRef.close(true);
  }

  getimageUrl(imageName: any, moduleName: any, timestamp: any) {
    return this.utilityService.getImageData(
      imageName,
      moduleName,
      650,
      665,
      null,
      timestamp
    );
  }
}

