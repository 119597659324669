import { Terms_and_conditionComponent } from "@app/checkout/terms_and_condition/terms_and_condition.component";
import { ColorSwatchSliderDialogComponent } from "@app/product-card/color-swatch-slider-dialog/color-swatch-slider-dialog.component";
import { ColorDistributionComponent } from "@app/product-details/color-distribution/color-distribution.component";
import { DeleteDailogComponent } from "@app/shared/delete-dailog/delete-dailog/delete-dailog.component";

export const DIALOG_REF: any = [
  {
    key: 'colorDistribution',
    component: ColorDistributionComponent,
    width: '605px',
    height: '570px',
  },

  {
    key: 'deleteDailog',
    width: '605px',
    height: '600px',
    component: DeleteDailogComponent
  },

  {
    key: 'terms&condition',
    width: '605px',
    height: '570px',
    Component: Terms_and_conditionComponent
  },

  {
    key: 'colorslider',
    width: '605px',
    height: '570px',
    Component: ColorSwatchSliderDialogComponent
  },
];
