<!-- <owl-carousel-o [options]="customSwatchSlider">
  <ng-template carouselSlide *ngFor="let color of data">
    <div class="choose-poduct-row">
      <div class="choose-block-wrap">
        <div class="choose-img-block"
        (mouseenter)="color.showTooltip = true"
        (mouseleave)="color.showTooltip = false" >
        <img alt="" [src]="
          color.swatch_image
            ? color.swatch_image
            : getimageUrl(
                'bci_noimage.png',
                'assets/images',
                650,
                665,
                null
              )
        " />
        <div *ngIf="color.showTooltip" class="custom-tooltip">
          {{ color.name }}
        </div>
      </div>
      </div>
    </div>
  </ng-template>
</owl-carousel-o> -->

<div class="pop-up-body show">
  <div class="pop-box plate-count color-block">
    <div class="pop-head">
      Color Swatches
      <a (click)="closeDialog()"><i class="icon-cross"></i> </a>
    </div>

    <div mat-dialog-content>
      <div class="chose-color custom">
        <owl-carousel-o [options]="customSwatchSlider">
          <ng-template carouselSlide *ngFor="let color of data.swatchArray">
            <div class="choose-poduct-row">
              <div class="choose-block-wrap">
                <div class="choose-img-block" >
                <img alt="" [src]="
                  color.swatch_image
                    ? color.swatch_image
                    : getimageUrl(
                        'bci_noimage.png',
                        'assets/images',
                        650,
                        665,
                        null
                      )
                " />
                <div class="custom-tooltip">
                  {{ color.name }}
                </div>
              </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
