import {
  Component,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject, PLATFORM_ID,
  HostListener
} from '@angular/core';
import { JsonPipe, NgClass, NgFor, NgIf, isPlatformBrowser } from '@angular/common';
import { CommonService } from '@app/core/services/common.service';
import { NavService } from '@app/shared/service/nav.service';
import { UtilityService } from '@app/core/services/utility.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SearchComponent } from '@app/search/search/search.component';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from '@app/core/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor, JsonPipe, LazyLoadImageModule, SearchComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderComponent implements OnInit {
  selectedMenuCollection: any;
  isMegaMenuVisible: boolean = false;
  selectedCollection: number = -1;
  selectedChildren: number = -1;
  megaMenuList: any[] = []
  imageDataFromApi: any;
  moduleName: string = "taxonomy";
  selectedImage: string;
  isLoggedIn: boolean = true;
  cartCount: number = 0;
  currentUrl: string = '';
  selectedCollectionIndex: number;
  isshowAccMenu: boolean= false;
  hoveredChild:any=null;

  constructor(
    private commonService: CommonService,
    private navService: NavService,
    private utilityService: UtilityService,
    public router: Router,
    private auth:AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }


  ngOnInit(): void {
    const sidebar = document.getElementById('toggle-menu');
    const content = document.getElementById('content');
    document.addEventListener('click', function(e:any) {
      if (!sidebar.contains(e.target) && !content.contains(e.target)) {
        var secondMenuElement = document.getElementById('second-menu');
        secondMenuElement.classList.remove("show");
        var toggleMenuElement = document.getElementById("toggle-menu");
        toggleMenuElement.classList.remove("show");
      }
    });

    if (isPlatformBrowser(this.platformId)) {

      this.getStore();
    }
    this.commonService.isLoggedIn$.subscribe(res => {
      this.isLoggedIn = res;
    })
    this.commonService.cartCount$.subscribe(res => {
      this.cartCount = res;
    })
    if (this.isLoggedIn == true) {
      this.commonService.getCartCount();
    }
  }
  // navigate to cart functionality
  navigateToCart() {
    const isLoggedIn = localStorage.getItem("isLoggedin");
    this.currentUrl = `/cart`;
    localStorage.setItem('prevURL', this.currentUrl);
    if (isLoggedIn === "true") {
      this.router.navigate(['/cart']);
    }
    else {
      this.router.navigate(['/log-in']);
    }
  }

  navigateToWishlist(){
    const isLoggedIn = localStorage.getItem("isLoggedin");
    this.currentUrl = `user/wish-list`;
    localStorage.setItem('prevURL', this.currentUrl);
    if (isLoggedIn === "true") {
      this.router.navigate(['user/wish-list']);
    }
    else {
      this.router.navigate(['/log-in']);
    }
  }

  // navigate to account settings
  navigateToMyAccount() {
    const isLoggedIn = localStorage.getItem("isLoggedin");
    this.currentUrl = `user/my-account`;
    localStorage.setItem('prevURL', this.currentUrl);
    this.closeSideBar();
    if (isLoggedIn === "true") {
      this.router.navigate(['/user/my-account']);
    }
    else {
      this.router.navigate(['/log-in']);
    }
  }

  showAccountMenu(){
    const isLoggedIn = localStorage.getItem("isLoggedin");
    if (isLoggedIn === "true") {
      this.isshowAccMenu= !this.isshowAccMenu
    }
    else {
      this.router.navigate(['/log-in']);
    }

  }

  getStore() {
    localStorage.setItem('storeId', '120');
    localStorage.setItem('businessUnitId', '4')
    this.getCollectionList(4);
  }

  getCollectionList(id: number) {
    this.navService.navItemList(id).subscribe(res => {
      this.megaMenuList = res[0]?.data[0]?.navigation_mega_menu[0]?.children;
      let images = this.megaMenuList.map(col => col.children?.map(e => e.images[0]?.image).filter(e => e))
      let merged = [].concat.apply([], images).filter(e => e)
      this.getImageData(merged)

    })
  }


  getImageUrl(imageName: string, status: boolean): string {
    const imageData = this.imageDataFromApi?.find((x: any) => x.image_name === imageName);
    const urlTimestamp = imageData ? imageData?.image_timestamp : null;
    return this.utilityService.getLazyImage(
      imageName,
      this.moduleName,
      status,
      ``, '', false, urlTimestamp
    );
  }

  getAssetsImageUrl(imageName: string, moduleName: string,){
    return this.utilityService.getAssetsImage(imageName, moduleName);
  }

  getImageData(imageNamesSet: string[]) {
    this.commonService.getImageData({ image_data: [...imageNamesSet], "modulename": "cms" }).subscribe(({ image_data }) => {
      this.imageDataFromApi = image_data;
    });
  }

  getimageUrl(imageName: any, moduleName: any, timestamp: any) {
    return this.utilityService.getImageData(imageName, moduleName, 650, 665, null, timestamp);
  }

  handleCloseEvent(event: any) {
    this.isMegaMenuVisible = event;
  }

  openCollections(collection:any){
    var element = document.getElementById("second-menu");
    element.classList.add("show");
    this.selectedMenuCollection = collection;
  }

  backMenu(value:any){
    var element = document.getElementById(value);
    element.classList.remove("show");
  }

  closeSideBar(){
    var secondMenuElement = document.getElementById('second-menu');
    secondMenuElement.classList.remove("show");
    var toggleMenuElement = document.getElementById("toggle-menu");
    toggleMenuElement.classList.remove("show");
  }

  megamenuClick(parent: any, child: any) {
    let queryParams = {};
    let slug = ''
    if (parent && !child) {
      queryParams = {};
      queryParams = { page: 1 };
      this.commonService.updatetaxonomySelectedData(child, parent);
      slug = parent.merchandisehierarchygroupslug
    } else {
      queryParams = { category: child.merchandisehierarchygroupname, page: 1 };
      this.commonService.updatetaxonomySelectedData(child, parent);
      slug = parent.merchandisehierarchygroupslug
    }

    let url = `/listing/${slug}`;
    localStorage.setItem('continueShoppingUrl', url);
    this.router.navigate([url], { queryParams: queryParams },);

    this.selectedCollection = -1;
    this.closeSideBar();
  }

  openSearchOption() {
    this.isMegaMenuVisible = true;
  }

  collectionParent(data: any) {
  }

  toggleMenu(){
    var element = document.getElementById("toggle-menu");
    element.classList.add("show");
  }

  urlFor(path:any){
    const url = `${environment.customerUrl}/${path}`;
    return url
  }

  selectIndexOfcollection(index:number){
    this.selectedCollection = +index;
    this.selectedCollectionIndex = index;
  }

  onSignout(): void{
    this.isshowAccMenu = false;
    this.auth.removeUserCredential1();
    this.commonService.setLoginValue(false);
  }

  orders(): void{
    const isLoggedIn = localStorage.getItem("isLoggedin");
    this.currentUrl = `user/my-orders`;
    localStorage.setItem('prevURL', this.currentUrl);
    this.closeSideBar();
    if (isLoggedIn === "true") {
      this.router.navigate(['/user/my-orders']);
    }
    else {
      this.router.navigate(['/log-in']);
    }
  }

  // Listen for clicks on the document
  @HostListener('document:click', ['$event'])
  onProfilerIconClick(event: Event): void {
    if (!(event.target as HTMLElement).closest('.icon-use-drop')) {
      this.isshowAccMenu = false
    }
  }
}

