import { Injectable, HostListener, Renderer2, Inject, RendererFactory2, PLATFORM_ID, NgZone } from '@angular/core';
import { Location, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BehaviorSubject, timer } from 'rxjs';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '@app/core/services/auth.service';
import { CartService } from "../../shared/service/cart.service";
import { UploaderService } from '@app/core/services/uploader.service';
import { UtilityService } from '@app/core/services/utility.service';
import { MetaService2 } from './meta.service';
import { environment } from 'environments/environment';
import { DIALOG_REF } from '../helper/constants/dialog-ref';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ErrorPopupComponent } from '@app/shared/error-popup/error-popup.component';

interface InventoryStock {
  count: number;
  data: any[];
  total_discount: number;
  total_offer_amount: number;
  totalamount: number;
  total_tax: number;
  store: number
}

@Injectable({
  providedIn: 'root',
})

export class CommonService {
  private renderer: Renderer2;
  globalRouterLoaderShow: BehaviorSubject<boolean>;
  globalRouterLoaderShow_: Observable<any>;

  activeProductFired: BehaviorSubject<any>;
  activeProductFired_: Observable<any>;
  mobileNav = false;
  cartItems: any = { 'cartCount': 0, 'cartDetails': [] };
  cartCount: number = 0;
  storeChangeOnCheckout: BehaviorSubject<any>;
  storeChangeOnCheckout_: Observable<any>;
  cartDetails: any;
  isLoggedin: boolean = false;
  businessUnitId = localStorage.getItem('businessUnitId');
  customerId: number;
  visitedPage: any = {};
  homeVisited: boolean = false;
  publishCalling: number = 0;
  allStoreList: any = [];
  searchText: BehaviorSubject<string>;
  taxonomySelectedData = new BehaviorSubject<any>({});
  taxonomySelectedData$ = this.taxonomySelectedData.asObservable();


  constructor(
    private metaService2: MetaService2,
    private http: HttpClient,
    readonly dataApiService: DataApiService,
    readonly metaService: Meta,
    readonly titleService: Title,
    private snackBar: MatSnackBar,
    readonly router: Router,
    readonly auth: AuthService,
    private cartService: CartService,
    readonly upload: UploaderService,
    public utilityService: UtilityService,
    private location: Location,
    rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone,
    public dialog: MatDialog,
  ) {
    this.globalRouterLoaderShow = new BehaviorSubject(false);
    this.globalRouterLoaderShow_ = this.globalRouterLoaderShow.asObservable();
    this.activeProductFired = new BehaviorSubject(false);
    this.activeProductFired_ = this.activeProductFired.asObservable();
    this.storeChangeOnCheckout = new BehaviorSubject(false);
    this.storeChangeOnCheckout_ = this.storeChangeOnCheckout.asObservable();
    this.renderer = rendererFactory.createRenderer(null, null);
    this.searchText = new BehaviorSubject('');
  }

  checkLogInState() {
    this.auth.authToken.subscribe((value: any) => {
      this.isLoggedin = value.isLoggedin === 'true' ? true : false;
    });
  }

  activeMenu(payload: any) {
    this.activeProductFired.next(payload)
  }

  updatetaxonomySelectedData(newValue: any, parentValue: any) {
    this.taxonomySelectedData.next({ selected: newValue, parent: parentValue });
  }

  resize(): void {
    if (window.innerWidth < 1100) {
      this.mobileNav = true;
    } else {
      this.mobileNav = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resize();
  }


  isUserAgentForSpeedTest() {
    let userAgentString: string = navigator.userAgent;
    return (userAgentString.includes("Google Page Speed Insights")) || (userAgentString.includes("Chrome-Lighthouse")) || (userAgentString.includes("GTmetrix")) || (userAgentString.includes("gtmetrix") || (userAgentString.includes("PageSpeed Insights")));
  }

  openSnackbar(messages: any, duration: number = 10000, closeButton: string = 'Dismiss'): void {
    if (Array.isArray(messages)) {
      this.openMultipleSnackbars(messages, closeButton);
    } else {
      this.openSingleSnackbar(messages, duration, closeButton);
    }
  }


  getCustomWishlist1(): Observable<any> {
    return this.http.get(`api/v1/mywishlist/`)
  }

  deleteItemFromWishlist(item_identifier: any, positemqulifier_id): Observable<any> {
    return this.http.delete(`api/v1/mywishlist/?item_identifier=${item_identifier}&positemqulifier_id=${positemqulifier_id}`)
  }

  timeoutId: any;
  private openMultipleSnackbars(messages: any[], closeButton: string): void {
    let accumulatedDuration = 0;

    messages.forEach((message, index) => {
      this.ngZone.run(() => {
        this.timeoutId = setTimeout(() => {
          this.openSnackbarMessage(message, closeButton, index, messages);
        }, accumulatedDuration);

        accumulatedDuration += message.duration;
      });
    });
  }

  private openSnackbarMessage(message: any, closeButton: string, index: number, messages: any[]): void {
    const openSnackbar = this.snackBar.open(message.text, closeButton, {
      duration: message.duration,
      verticalPosition: !this.mobileNav ? 'bottom' : 'top',
    });

    openSnackbar.afterDismissed().subscribe((event: any) => {
      if (event.dismissedByAction) {
        this.resize();
        clearTimeout(this.timeoutId);

        const nextIndex = index + 1;
        if (nextIndex < messages.length) {
          const nextMessage = messages[nextIndex];
          setTimeout(() => {
            this.openSnackbarMessage(nextMessage, closeButton, nextIndex, messages);
          }, 500);
        }
      }
    });
  }

  private openSingleSnackbar(message: any, duration: number, closeButton: string): void {
    this.resize();
    this.openDialog(message)
  }


  openDialog(message:any,) {
    const dialogRef = this.dialog.open(ErrorPopupComponent, {
      width: '500px',
      data: {
        title: '',
        message: message
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog was closed');
    });
  }

  setGlobalRouterLaoder(payload: any) {
    this.globalRouterLoaderShow.next(payload);
  }

  storeChangeOnCheckoutCall(payload: any) {
    this.storeChangeOnCheckout.next(payload);
  }
  /**
   * Method to get data by id.
   * @param requestData Request data for api.
   * @returns Observable of httpclient.
   */
  getDataById(requestData: any): any {
    return this.dataApiService.getDataById(requestData).pipe(
      map(data => {
        return data;
      }),
    );
  }

  /**
   * Method to get cms page based on slug.
   * @param cmsSlug Slug for cms page to be fetched.
   * @returns Observable of cms page.
   */
  getCmsPage(cmsSlug: string): any {
    return this.dataApiService.getCmsPage(cmsSlug);
  }
  getCmsPageWithOffset(cmsSlug: string, limit: number, offset: number): any {
    return this.dataApiService.getCmsPageWithOffset(cmsSlug, limit, offset);
  }
  /**
       * Method to get Category details Based on category ID.
       * @param catId Category Id;
       * @returns Observable of Category page.
       */
  getCategoryDetails(catname: string): any {
    return this.dataApiService.getCategoryDetails(catname).pipe(
      map(data => {
        return data;
      }),
    );
  }
  /**
   * Method to encode Any ID.
   * @param str received original ID.
   * @returns it generates new ID.
   */
  getEncode(str: any) {
    const specialString = 'aasfsfrsdgbnghyufufsddtrdsfgsdgsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhhrtyerteryer';
    const encription = btoa(str + specialString);
    return encription;
  }
  /**
   * Method to Decode Any ID.
   * @param str received Encoding ID.
   * @returns it generates original ID.
   */
  getDecode(str: any) {
    const specialString = 'aasfsfrsdgbnghyufufsddtrdsfgsdgsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhhrtyerteryer';
    let decription: any = atob(str);
    decription = decription.split(specialString);
    return decription[0];
  }
  /**
   * @returns Observable of Navigation Item.
   */
  getNavigationItem(): any {
    return this.dataApiService.getNavigationItem();
  }

  /**
   * @returns Observable of Navigation Menu.
   */
  getTaxonomyNavigation(functionId?: any): any {
    if (functionId) {
      return this.dataApiService.getTaxonomyNavigation(functionId);
    } else {
      return this.dataApiService.getTaxonomyNavigation();
    }
  }

  getTaxonomyLevelWiseNavigation(functionId?: any, parentId?: any) {
    if (functionId) {
      if (parentId)
        return this.dataApiService.getTaxonomyLevelWiseNavigation(functionId, parentId);
      else
        return this.dataApiService.getTaxonomyLevelWiseNavigation(functionId);
    } else {
      return this.dataApiService.getTaxonomyLevelWiseNavigation();
    }
  }

  getTaxonomyLevelWithSlug(slug: string): any {
    return this.dataApiService.getTaxonomyLevelWithSlug(slug);
  }

  getNavFooter() {
    return this.dataApiService.getNavFooter()
  }
  getTaxonmyText(id: any) {
    return this.dataApiService.getTaxonmyText(id);
  }



  /**
   * @returns Observable of Product Listing Page.
   */
  getProductList(limit: any, offset: any, params: any, searchText?: string) {
    if (searchText) {
      return this.dataApiService.getProductListSearch(limit, offset, searchText)
    } else {
      return this.dataApiService.getProductList(limit, offset, params);
    }
  }


  getProductBySlug(slug: any) {
    return this.dataApiService.getProductBySlug(slug);
  }

  getProductSpecificationBySlug(slug: any) {
    return this.dataApiService.getProductSpecificationBySlug(slug);
  }


  getProductListWithFilter(filterObj: any) {
    return this.dataApiService.getProductListFilter(filterObj);
  }

  getFilterList(params: any, type?: any) {
    params.isfilteronly = "True";
    params.functionid = 4;
    if (type) {
      return this.dataApiService.getGlobalFilterList();
    } else {
      return this.dataApiService.getFilterList(params);
    }
  }

  getGlobalFilterList() {
    return this.dataApiService.getGlobalFilterList();
  }

  getProductBySKU(sku: any) {
    return this.dataApiService.getProductBySKU(sku).pipe(
      map(data => {
        return data;
      })
    )
  }
  getProductListInCMS(slug: string, filterData: any, itemId: any, params?: any) {
    if (filterData) {
      return this.dataApiService.getProductListforCMS(null, filterData, null);
    }
  }
  getLandingPageSlug(ids: any) {
    return this.dataApiService.getLandingPageSlug(ids);
  }
  getRelatedItems(itemStr?: any): any {
    return this.dataApiService.getRelatedItems(itemStr).pipe(
      map(data => {
        return data;
      }),
    );
  }

  // getRelatedItems() {
  //   return this.dataApiService.getRelatedItems();


  // }

  /**
 * @returns Observable of Product Listing Page.
 */
  getBrandList(itemId?: number) {
    if (itemId) {
      return this.dataApiService.getBrandList(itemId);
    } else {
      return this.dataApiService.getBrandList();
    }
  }
  getSubBrandList(itemId: number) {
    return this.dataApiService.getSubBrandList(itemId);
  }

  getAllCustomFieldList() {
    return this.dataApiService.getAllCustomFieldList();
  }

  getCollectionsData(requestData: any): any {
    return this.dataApiService.getListingData(requestData).pipe(
      map(data => {
        return data;
      }),
    );
  }

  getAllData(requestData: any): Observable<any> {
    return this.dataApiService.getAllData(requestData).pipe(
      map(data => {
        return data;
      }),
    );
  }

  goBack() {
    this.location.back();
  }
  isCalledOnce: boolean = false;
  setAdMetaData(seoData: any, url: any) {
    if (this.shouldSkipMetaUpdate()) {
      return;
    }

    this.updateTitleAndDescription(seoData);

    this.updateTags([
      { property: 'og:type', content: seoData.og_type },
      { property: 'og:url', content: seoData.og_url },
      { property: 'og:title', content: seoData.og_title },
      { property: 'og:image', content: seoData.og_image },
      { property: 'og:description', content: seoData.og_description },
      { property: 'og:locale', content: seoData.og_local },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '630' },
      { property: 'og:site_name', content: "bcipottery" },
      { property: 'twitter:title', content: seoData.title },
      { name: 'title', content: seoData.title },
      { name: 'description', content: seoData.description },
      { name: 'keywords', content: seoData.keywords },
      { name: 'locale', content: seoData.meta_location },
      { name: 'robots', content: seoData.meta_robots },
      { name: 'referrer', content: seoData.meta_referral },
      { name: 'rights', content: seoData.meta_rights },
      { name: 'viewport', content: seoData.view_point },
      { name: 'twitter:description', content: seoData.og_description },
      { property: 'twitter:description', content: seoData.og_description },
      { property: 'twitter:card', content: seoData.twitter_card },
    ]);

    this.updateTwitterTags(seoData.feature_image);

    this.updateAdditionalTags();

    this.updateGeoAndAuthorTags();

    this.updateCanonicalUrl(url);

    this.updateScripts(seoData);

    if (isPlatformServer(this.platformId)) {
      this.isCalledOnce = true;
    }

    return true;
  }

  shouldSkipMetaUpdate(): boolean {
    return isPlatformServer(this.platformId) && this.isCalledOnce;
  }

  updateTitleAndDescription(seoData: any): void {
    if (seoData.title && seoData.title !== '') {
      this.titleService.setTitle(seoData.title);
      this.metaService.updateTag({ property: 'twitter:title', content: seoData.title });
      this.metaService.updateTag({ name: 'title', content: seoData.title });
    }

    if (seoData.description && seoData.description !== '') {
      this.metaService.updateTag({ name: 'description', content: seoData.description });
    }
  }

  updateTags(tags: { property?: string; name?: string; content: string }[]): void {
    tags.forEach(tag => {
      if (tag.content && tag.content !== '') {
        this.metaService.updateTag(tag);
      }
    });
  }


  updateTwitterTags(featureImage: string): void {
    if (featureImage && featureImage !== '') {
      this.metaService.updateTag({ property: 'twitter:image', content: featureImage });
      this.metaService.updateTag({ name: 'twitter:image', content: featureImage });
      this.metaService.updateTag({ name: 'twitter:site', content: '@bcipottery' });
    }
  }

  updateAdditionalTags(): void {
    this.metaService.updateTag({ name: 'geo.country', content: 'US' });
    this.metaService.updateTag({ name: 'author', content: 'bcipotterys' });
  }

  updateGeoAndAuthorTags(): void {
    this.metaService.updateTag({ property: 'og:image:width', content: '1200' });
    this.metaService.updateTag({ property: 'og:image:height', content: '630' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'bcipotterys' });
  }

  updateCanonicalUrl(url: string): void {
    if (!environment.isDevServer) {
      const canonicalUrl = 'https://www.bcipotterys.com' + url;
      const regex = /^https:\/\/www\.bcipotterys\.com\/user\/([^\/]+)\/$|^https:\/\/www\.bcipotterys\.com\/(cart|checkout)(\/([^\/]+)\/)?$/;
      const callCanonical = canonicalUrl.includes('.js') || canonicalUrl.includes('.png') || canonicalUrl.includes('.ico') || canonicalUrl.includes('.css') || regex.test(canonicalUrl);
      this.metaService2.removeTags();
      if (!callCanonical && canonicalUrl !== 'https://www.bcipotterys.com/') {
        this.metaService2.addTag({ rel: 'canonical', href: canonicalUrl });
      }
    }
  }

  updateScripts(seoData: any): void {
    if (seoData.cms_script && seoData.cms_script !== '') {
      this.appendGlobalScript(seoData.cms_script);
    }
    if (seoData.cms_noscript && seoData.cms_noscript !== '') {
      this.appendGlobalNoScript(seoData.cms_noscript);
    }
    if (seoData.cms_function_script && seoData.cms_function_script !== '') {
      this.appendGlobalFunctionScript(seoData.cms_function_script);
    }
  }

  appendGlobalScript(script: any) {
    let scriprtArr = script.split("</script>");
    scriprtArr.forEach((data: any) => {
      if (data) {
        var regex = /<script.*?src="(.*?)"/gmi;
        var regexOne = /<script.*?id="(.*?)"/gmi;
        var url = regex.exec(data);
        var id = regexOne.exec(data);
        let chatScript = document.createElement("script");
        chatScript.type = "text/javascript";
        chatScript.async = true;
        if (url) {
          chatScript.src = url[1];
        }

        if (id) {
          chatScript.id = id[1];
        }

        document.body.appendChild(chatScript);
      }

    });
  }

  appendGlobalNoScript(noscript: any) {
    let noscriprtArr = noscript.split("</noscript>");

    noscriprtArr.forEach((data: any) => {
      if (data !== '') {
        const newScript = data.replace('<noscript>', '');

        var regex = /<iframe.*?src="(.*?)"/gmi;
        var regexHeight = /(height)=["']([^"']*)["']/gi;
        var regexwidth = /(width)=["']([^"']*)["']/gi;
        var regexsrc = /(src)=["']([^"']*)["']/gi;
        var regexstyle = /(style)=["']([^"']*)["']/gi;


        var src = regexsrc.exec(newScript);
        var height = regexHeight.exec(newScript);
        var width = regexwidth.exec(newScript);


        const noScript = this.renderer.createElement('noscript') as HTMLScriptElement;
        const iframe = this.renderer.createElement('iframe') as HTMLIFrameElement;
        iframe.src = src[2];

        iframe.width = width[2];
        iframe.height = height[2];
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        this.renderer.appendChild(noScript, iframe);
        document.body.appendChild(noScript);

      }
    });
  }
  appendGlobalFunctionScript(script: any) {
    let scriprtArr = script.split("</script>");
    scriprtArr.forEach((a: any) => {
      if (a !== '') {
        const newScript = a.replace('<script>', '');
        const script2 = this.renderer.createElement('script') as HTMLScriptElement;
        const scriptBody = this.renderer.createText(`${newScript}`);
        this.renderer.appendChild(script2, scriptBody);
        document.head.appendChild(script2);
      }
    })

  }

  addScript(url: string, type?: string, id?: string) {
    let chatScript = document.createElement("script");
    chatScript.type = type || "text/javascript";
    chatScript.async = true;
    chatScript.src = url;

    if (id) {
      chatScript.id = id;
    }

    document.body.appendChild(chatScript);
  }

  getBaseUrl() {
    const baseUrl = window.location.origin;
    return baseUrl;
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
          err => {
            reject(err);
          });
      }
    });

  }


  /**
   * Method to post recent product view parameter.
   * @param bodyobject object of key value;
   * @returns Observable of recent view product.
   */
  getRecentProductList(): any {
    return this.dataApiService.getRecentProductList().pipe(
      map((data) => {
        return data;
      })
    );
  }

  postRecentProduc(obj:any): any {
    return this.dataApiService.postRecentProduct(obj).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getCustomerLikeProductList(slug: any): any {
    return this.dataApiService.getCustomerLikeProductList(slug).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Get Feature List
   */

  getfeatureList(slug: any): any {
    return this.dataApiService.getfeatureList(slug).pipe(
      map((data) => {
        return data;
      })
    );
  }


  /**
   * Method to post review rating parameter.
   * @param bodyobject object of key value;
   * @returns Observable of review rating.
   */
  postProductReviewsRatings(bodyobject: any): any {
    return this.dataApiService.postProductReviewsRatings(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Method to put review rating parameter.
   * @param bodyobject object of key value;
   * @returns Observable of review rating.
   */
  productReviewsRatingsUpdate(review_id: any, bodyobject: any): any {
    return this.dataApiService.productReviewsRatingsUpdate(review_id, bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Method to post question parameter.
   * @param bodyobject object of key value;
   * @returns Observable of question.
   */
  postQuestion(bodyobject: any): any {
    return this.dataApiService.postQuestion(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getCommonDropDowndata(objectIds: any): any {
    return this.dataApiService.getCommonDropDowndata(objectIds).pipe(
      map(data => {
        return data;
      }),
    );
  }
  getStateByCountry(countryId: any): any {
    return this.dataApiService.getStateByCountry(countryId).pipe(
      map(data => {
        return data;
      })
    )
  }
  getCountry(): any {
    return this.dataApiService.getCountry().pipe(
      map(data => {
        return data;
      })
    )
  }
  wishListData(wishItemId?: any, active?: boolean): any {
    return this.dataApiService.wishListData(wishItemId, active).pipe(
      map(data => {
        return data;
      })
    )
  }

  getCartDetails(customerOrderId?: any): any {

    return this.dataApiService.getCartDetails(customerOrderId).pipe(
      map(data => {
        return data;
      })
    )
  }

  getCartList(): any {
    return this.dataApiService.getCartList().pipe(
      map(data => {
        return data;
      })
    )
  }



  addItemsToWishlist(item_ids: any): Observable<any> {
    return this.http.put<any>(`api/v1/mywishlist/`, item_ids);
  }




  openCmsUrl(url: string, openInSameTab: boolean) {

    if (openInSameTab) {
      if (url.startsWith(window.location.origin)) {
        window.location.href = url;
      } else {
        window.location.href = url;
      }
    } else {
      window.open(`${url}`, '_blank');
    }
  }

  setCartItems(payload: any) {
    payload?.cartDetails.map((x: any) => x.store = payload?.store);
    this.cartItems = { ...payload };
    this.cartCount = payload.cartCount;
    this.cartDetails = [...payload.cartDetails];
    this.cartService.setCount(this.cartCount);
    this.cartService.setCartItemArray([...payload.cartDetails]);
    this.cartService.setTotalOfferAmount(payload.totalOfferAmount);
    this.cartService.setTotalDiscount(payload.totalDiscount);
    this.cartService.setTotalAmount(payload.totalAmount);
    this.cartService.setTotalTaxAmount(payload.total_tax);
    this.cartService.setBookingData = (payload.booking_flag ? payload.booking_data : {});
    this.cartService.setCartDetails(payload.cartDetails);
    this.cartService.setIsCartSet(true);
  }

  getCount() {
    return this.cartCount;
  }

  clearBookingData() {
    localStorage.removeItem('booking_flag');
    localStorage.removeItem('booking_store');
    localStorage.removeItem('pickup_window_id');
  }

  // Getter method to access the flag value
  getPoupCalled(): boolean {
    return this.cartService.poupCalled;
  }

  // Setter method to update the flag value
  setPoupCalled(value: boolean): void {
    this.cartService.poupCalled = value;
  }

  // Helper methods for better readability
  handleExistingCart(existCartData: any): void {
    this.cartService.setCartDuringLogin(existCartData);
    this.callAfterFetchWithLogin(existCartData);

    const { booking_flag, store } = existCartData;

    if (booking_flag) {
      localStorage.setItem('booking_flag', booking_flag);
      localStorage.setItem('booking_store', store);

    } else {
      // This is for the local storage works if the cart data is not from booking
      localStorage.setItem('booking_flag', 'false');
      localStorage.removeItem('booking_store');
    }
  }


  handleEmptyCart(existCartData: any): void {
    this.setCartItems({
      'cartCount': existCartData.count,
      'cartDetails': existCartData.data,
      'totalDiscount': existCartData.total_discount,
      'totalOfferAmount': existCartData.total_offer_amount,
      'totalAmount': existCartData.totalamount,
      'total_tax': existCartData.total_tax
    });
    localStorage.setItem('booking_flag', 'false');
    localStorage.removeItem('booking_store');
    localStorage.removeItem('pickup_window_id');
    let element = document.getElementById("Cart-Box");
    element?.classList.remove("active");
  }

  async callAfterFetchWithLogin(data: any) {
    try {
      const inventoryStock: InventoryStock = await this.getInventoryStockWithLogin(data);

      if (Object.keys(inventoryStock).length > 0) {
        const cartItems = {
          'cartCount': inventoryStock.count,
          'cartDetails': inventoryStock.data,
          'totalDiscount': inventoryStock.total_discount,
          'totalOfferAmount': inventoryStock.total_offer_amount,
          'totalAmount': inventoryStock.totalamount,
          'total_tax': inventoryStock.total_tax,
          'booking_flag': data?.booking_flag,
          'booking_data': data?.booking_data,
          'store': inventoryStock.store
        };

        this.setCartItems(cartItems);
      }
    } catch (error) {
      console.error('Error fetching inventory stock:', error);
      // Handle the error if needed
    }
  }

  getInventoryStockWithLogin(data: any): Promise<InventoryStock> {
    return new Promise((resolve: any, reject: any) => {
      let newArr: any = '';
      data['data'].forEach((e: any, i: number) => {
        if (i == 0) {
          newArr += 'items=' + e.item_id + ',' + e.positemqualifier;
        } else {
          newArr += '&items=' + e.item_id + ',' + e.positemqualifier;
        }
      })
      if (newArr !== '') {
        this.checkItemsInStock(newArr).subscribe((value: any) => {
          if (value && value.stock && value.stock.length > 0) {
            value.stock.forEach((e: any, index: number) => {
              data['data'].forEach((f: any, i: number) => {
                if (e.item_id == f.item_id) {
                  f.rest_stock = e.stock;
                  f.ignore_stock = e.ignore_stock;
                }
              })
            })
            resolve(data);
          }
        })
      }
    })
  }
  // Stocks Finder after conversion starts here for user with login end here.



  // Stocks Finder after conversion starts here for user without login starts here.
  /**
   * callAfterFetchWithoutLogin() this is used for getting the stocks as per the qualifier.
   * This is a async function & will wait for  getInventoryStockWithoutLogin() method.
   * @param data
   */
  async callAfterFetchWithoutLogin(data: any) {
    try {
      const response: InventoryStock = await this.getInventoryStockWithoutLogin(data);

      if (response) {
        const { data: cartLocalDataInfo, total_tax, total_discount, total_offer_amount, totalamount, booking_id, event_id, ordertype, storeid } = response[0];

        this.setCartItems({
          'cartCount': cartLocalDataInfo.length,
          'cartDetails': cartLocalDataInfo,
          'totalDiscount': total_discount,
          'totalOfferAmount': total_offer_amount,
          'totalAmount': totalamount,
          'total_tax': total_tax || 0,
          'booking_flag': ordertype ? true : false,
          'booking_data': { booking_id, event_id, ordertype, storeid }
        });
      }
    } catch (error) {
      console.error('Error fetching inventory stock without login:', error);
      // Handle the error if needed
    }
  }
  getInventoryStockWithoutLogin(data: any): Promise<InventoryStock> {
    return new Promise((resolve: any, reject: any) => {
      let newArr: any = '';
      data[0].data.forEach((e: any, i: number) => {
        if (i == 0) {
          newArr += 'items=' + e.itemdata.Item.id + ',' + e.positemqualifier;
        } else {
          newArr += '&items=' + e.itemdata.Item.id + ',' + e.positemqualifier;
        }
      })
      if (newArr !== '') {
        this.checkItemsInStock(newArr).subscribe((value: any) => {
          if (value && value.stock && value.stock.length > 0) {
            value.stock.forEach((e: any, index: number) => {
              data[0].data.forEach((f: any, i: number) => {
                if (e.item_id == f.itemdata.Item.id) {
                  f.rest_stock = e.stock;
                  f.ignore_stock = e.ignore_stock;
                }
              })
            })
            resolve(data);
          }
        })
      }
    })
  }

  deleteCartNew(customerOrderProductLineItemId, otherParameters) {
    return new Promise((resolve, reject) => {
      this.deleteCart(customerOrderProductLineItemId, null).subscribe((value: any) => {
        resolve(value)
      }, error => {
        // reject(error)
      })
    });
  }
  cartDataArray: any;

  // ++ Before Login Cart section implementation

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  getNavigationURL() {
    return this.dataApiService.getNavigationURL().pipe(
      map(data => {
        return data;
      })
    )
  }


  getEncodeWithString(number: any) {
    let key_option = [
      "FghfdghfhfyghfGHFGHdGHdGHFgh",
      "HKJfgHJgHJGhjg897897897JGhjg",
      "HhkjGhjgUJghj8976GHhjhjhj789k",
      "HJfghjG8968fhjf76kj87HJJghjgh",
      "ouhkjfhDTYghfhtyg89ghlhkvhj76",
      "GhjFGfHGhjg8GHJghj789Ghjg78FH",
      "hjguyHJghjHJGGHJ6hjgUI9tuiKJg",
      "GH89FUKJghjfHJghjFHfgDFyg789g",
      "hkjdfGGHJ78GHghj7gF78Fgkjhjg4",
      "GHJDGHJDsghBHKJlkVG7586DXhj5c",
      "G908g76fgygh786fgughifghIJYH",
      "BNCFGHJcHJ786FGHdghSR78FC786c",
      "ghjVYGvcxUYCG896GHvuyV784Hcu",
      "hVUY5HJG89Gvhjgkj89fKJH756DF",
      "HJ786FGHGHJ789FghkjFH789FKJ89",
      "ugkGH789FGFGHJ786DHJ78fgHJ789F"
    ];
    let rand_index = this.getRandomInt(0, 15);
    let auth_key1 = key_option[rand_index];
    rand_index = this.getRandomInt(0, 15);
    let auth_key2 = key_option[rand_index];
    let message = auth_key1 + number + auth_key2;
    let encription = btoa(message);
    return encription;
  }

  getSearch(url: string): any {
    return this.dataApiService.getSearch(url).pipe(
      map(data => {
        return data;
      })
    )
  }

  addToCart(cartObj: any): any {
    return this.dataApiService.addToCart(cartObj).pipe(
      map(data => {
        return data;
      })
    )
  }

  deleteCart(customerorderlineitemid: number, customerOrderId: number) {
    return this.dataApiService.deleteCart(customerorderlineitemid, customerOrderId).pipe(
      map(data => {
        return data;
      })
    )
  }

  getCustomWishlist(customerid: number): any {
    return this.dataApiService.getCustomWishlist(+customerid).pipe(
      map(data => {
        return data
      })
    )
  }

  myAddressGenerate(userId: number, object?: any): any {
    return this.dataApiService.myAddressGenerate(userId, object).pipe(
      map(data => {
        return data;
      })
    )
  }
  myAddressAction(userId: number, object: any, type: string): any {
    return this.dataApiService.myAddressAction(userId, object, type).pipe(
      map(data => {
        return data;
      })
    )
  }

  getWishList() {
    let customerId: any = this.auth.customer_id;
    this.getCustomWishlist(customerId).subscribe((value: any) => {
      this.auth.setWishList(value);
    });
  }

  myOrderListFetch(myOrderListFetch: number, offset?: number, limit?: number, startDate?: string, endDate?: string, orderState?: any, searchValue?: string) {
    return this.dataApiService.myOrderListFetch(myOrderListFetch, offset, limit, startDate, endDate, orderState, searchValue).pipe(
      map(data => {
        return data;
      })
    )
  }
  getCustomerBillingAddressOrderSummary(customerId: number) {
    return this.dataApiService.getCustomerBillingAddressOrderSummary(customerId).pipe(
      map(data => {
        return data;
      })
    )
  }

  checkCuponValidation(code: string) {
    return this.dataApiService.checkCuponValidation(code).pipe(
      map(data => {
        return data;
      })
    )
  }


  orderPickupAuthorize(obj?: any, customer_id?: number) {
    return this.dataApiService.orderPickupAuthorize(obj, customer_id).pipe(
      map(data => {
        return data;
      })
    )
  }
  stripePayment(stripeObj: any) {
    return this.dataApiService.stripePayment(stripeObj).pipe(
      map(data => {
        return data;
      })
    )
  }
  getStripeCardList() {
    return this.dataApiService.getStripeCardList().pipe(
      map(data => {
        return data;
      })
    )
  }
  saveBillingAddress(data: any, type?: string) {
    return this.dataApiService.saveBillingAddress(data, type).pipe(
      map(data => {
        return data;
      })
    )
  }
  checkPaymentMethod(key: string) {
    return this.dataApiService.checkPaymentMethod(key).pipe(
      map(data => {
        return data;
      })
    )
  }
  stripePaymentAuthorized(obj: any) {
    return this.dataApiService.stripePaymentAuthorized(obj).pipe(
      map(data => {
        return data;
      })
    )
  }
  giftCardPaymentCapture(obj: any) {
    return this.dataApiService.giftCardPaymentCapture(obj).pipe(
      map(data => {
        return data;
      })
    )
  }
  getProductDefaultImage(product, fromProductItem = false, qualifier?: any) {
    if (!product) {
      return null;
    }
    let defaultImage: any = {
      image: '',
      alt_tag: product?.Item?.name
    };

    const itemImages = product.itemimages;

    if (!itemImages || itemImages.length === 0) {
      return defaultImage;
    }

    if (fromProductItem && itemImages[0]?.positemqulifier_id) {
      const filteredItemImages = itemImages.filter(x => x.positemqulifier_id === (qualifier || product.default_qualifier || product?.Item?.default_qualifier));
      if (filteredItemImages.length > 0) {
        const defaultImageArray = filteredItemImages.find(item => item.is_default === 'Yes');
        return defaultImageArray || filteredItemImages[0];
      }
    }

    const defaultImageArray = itemImages.filter(item => item.is_default === 'Yes');
    defaultImage = product.itemimages[0];
    if (defaultImageArray.length > 0) {
      defaultImage = defaultImageArray[0];
      return defaultImage;
    }

    return defaultImage;
  }

  getProductDefaultImageCMS(product, fromProductItem = false, qualifier?: any) {
    if (!product) {
      return null;
    }
    let defaultImage: any = {
      image: '',
      alt_tag: product?.data[0].image[0].name
    };

    const itemImages = product.data[0].image;

    if (!itemImages || itemImages.length === 0) {
      return defaultImage;
    }

    if (fromProductItem && itemImages[0]?.positemqulifier_id) {
      const filteredItemImages = itemImages.filter(x => x.positemqulifier_id === (qualifier || product.default_qualifier || product?.Item?.default_qualifier));
      if (filteredItemImages.length > 0) {
        const defaultImageArray = filteredItemImages.find(item => item.is_default === 'Yes');
        return defaultImageArray || filteredItemImages[0];
      }
    }

    const defaultImageArray = itemImages.filter(item => item.is_default === 'Yes');
    defaultImage = product.data[0].image[0];
    if (defaultImageArray.length > 0) {
      defaultImage = defaultImageArray[0];
      return defaultImage;
    }

    return defaultImage;
  }

  getNotFoundImageUrl(width: any, height: any) {
    return this.utilityService.getLazyImage('', '', false, `${width}x${height}`);
  }

  getNotFoundImage(event: any, width: any, height: any) {
    let defaultImage = this.getNotFoundImageUrl(width, height);
    event.target.src = defaultImage;
  }

  getPrice(item: any) {
    let quantity: number = 1;
    if (item.hasOwnProperty('quantity')) {
      quantity = item.quantity;
    }
    let price: any = 0;
    item.regularpriceData = item.regularpriceData.filter((element: any) => +element.positemqulifier == +item.positemqualifier);
    if (item.regularpriceData.length > 0) {
      price = item.regularpriceData[0].price;
    }
    item.breakpriceData = item.breakpriceData.filter((element: any) => +element.positemqulifier == +item.positemqualifier);
    let breakprice: any = item.breakpriceData.filter((x: any) => x.quantityfrom <= quantity && x.quantityto >= quantity);
    breakprice.sort((a: any, b: any) => a.price - b.price);
    if (breakprice.length > 0) {
      price = breakprice[0].price;
    }
    item.regularPrice = price;

    let offer_list: any = [];
    if (item.hasOwnProperty('offer_list')) {
      let offer_list1: any = item.offer_list.filter((element: any) => !('posqualifier' in element));
      let offer_list2: any = item.offer_list.filter((element: any) => ('posqualifier' in element) && +element.posqualifier === +item.positemqualifier);
      offer_list = [...offer_list1, ...offer_list2];
    }

    /**
     * rewardgroup =>  2 for percentage   rewardgroup => 1 for flat discount  rewardgroup => 3  New offer, rewardlimitamount*
     * */
    offer_list = offer_list.filter((x: any) => +x.minimumquantity <= +quantity && (+x.maximumquantity >= +quantity || +x.maximumquantity == 0) && +x.subtotalminimumamount <= +price * quantity && (+x.subtotalmaximumamount >= +price * quantity || +x.subtotalmaximumamount == 0) && ((+x.rewardgroup == 1 && +price > +x.rewardlimitamount) || +x.rewardgroup == 2 || (+x.rewardgroup == 3 && +price > +x.rewardlimitamount)));
    let oferPriceArray: any = [];
    offer_list.forEach((element: any, index: any) => {
      let offerPrice: any = 0;
      if (element.rewardgroup === 1) {
        offerPrice = price - element.rewardlimitamount;
      }
      else if (element.rewardgroup === 2) {
        offerPrice = price - price * element.rewardlimitamount / 100;
      }
      else if (element.rewardgroup === 3) {
        offerPrice = element.rewardlimitamount;
      }
      offerPrice = offerPrice ? offerPrice.toFixed(2) : 0;
      oferPriceArray.push(offerPrice);
      offer_list[index].offerPrice = +offerPrice;
    });

    offer_list.sort((a: any, b: any) => a.offerPrice - b.offerPrice);

    oferPriceArray.sort((a: any, b: any) => a - b);

    let offerprice: any = oferPriceArray.length > 0 ? oferPriceArray[0] : 0;

    item.offerPrice = +offerprice;

    item.discountPercentage = +(item.offerPrice > 0 ? ((item.regularPrice - item.offerPrice) * 100 / item.regularPrice).toFixed(2) : 0);

    item.rewarddescription = offer_list.length > 0 ? offer_list[0]?.rewarddescription : '';

    item.price = +(item.offerPrice > 0 ? item.offerPrice : item.regularPrice);

  }


  /*getValidOfferList(offer_list, price, quantity){

  }*/

  countLines(domId: any) {
    let divHeight = 0;
    let el: any = document.querySelector('#' + domId);
    if (el) {
      divHeight = el.offsetHeight
    }
    return divHeight;
  }


  checkNumberValidation(event: any) {
    var key = event.keyCode;
    if (key === 43 || key === 45 || key === 46) {
      event.preventDefault();
    }
  }

  stockAvailCheckerAsPerUOM(item: any) {
    if (item.Item.positemqulifier_id == undefined && item?.positemqualifier) {
      item.Item.positemqulifier_id = item.positemqualifier;
    }
    return new Promise<any>((resolve: any, reject: any) => {
      let newArr: any = '';
      if (item) {
        newArr = 'items=' + item.Item.id + ',' + item.Item.positemqulifier_id;
      }
      if (newArr !== '') {
        this.checkItemsInStock(newArr).subscribe((value: any) => {
          if (value && value.stock && value.stock.length > 0) {
            if (value.stock[0].item_id == item.Item.id) {
              resolve(value.stock[0]);
            }
          }
        })
      }
    })
  }



  getProductDetails(product: any, qualifierId_?: number, eventStoreId?: any) {
    this.setQualifiers(product);
    this.setWishlistStatus(product);
    this.setRatingAndReviewCount(product);
    this.setPosItemQualifiers(product, eventStoreId);
    this.setSellingRules(product, eventStoreId);
    this.setCollectionWisePrice(product, eventStoreId);
    this.setUOMConversion(product, eventStoreId);

    return product;
  }

  private setQualifiers(product: any) {
    let qualifierID: number;
    if (product.data[0].positemqualifier_id !== undefined) {
      product.Item.id = +product.Item.id;
      qualifierID = product.data[0].positemqualifier_id;
      product.positemqualifier = qualifierID;
      product.wishlist = false;
    }
  }

  private setWishlistStatus(product: any) {
    this.auth?.wishlistData.subscribe((wishlist: any) => {
      if (wishlist != null) {
        if (wishlist.length > 0) {
          let wistlistproduct = wishlist.filter((item: any) => +item.id === +product.Item.id);
          if (wistlistproduct.length > 0) {
            product.wishlist = true;
          }
        }
      }
    });
  }

  private setRatingAndReviewCount(product: any) {
    product.rating = 0;
    product.review_count = 0;
    if (product.rating) {
      product.rating = product.rating.toFixed(0);
    }
    if (product.review_count) {
      product.review_count = product.Item.review_count;
    }
  }
  /**
   * setPosItemQualifiers() method started
   * @param product
   * @param eventStoreId
   */
  private setPosItemQualifiers(product: any, eventStoreId?: any) {
    const positemqualifiers = this.getActivePosItemQualifiers(product);

    const {
      allQualifierWisePriceArray,
      regularpriceData,
      breakpriceData,
      posidentity
    } = this.prepareQualifierData(product, positemqualifiers, eventStoreId);

    product.allQualifierWisePriceArray = allQualifierWisePriceArray;
    product.regularpriceData = regularpriceData;
    product.breakpriceData = breakpriceData;

    const defaultQualifier = this.getDefaultQualifier(positemqualifiers);

    product.special_instructions = defaultQualifier?.special_instructions || '';
    product.enddate = defaultQualifier?.enddate || '';

    product.positemqualifierName = product.positemqualifierName || defaultQualifier?.positemqulifiername || '';
    product.positemqualifier = product.positemqualifier || defaultQualifier?.positemqulifier || 0;

    const positemqulifierPosidentity = this.getPositemqulifierPosidentity(posidentity, product.positemqualifier);

    product.actual_stock = positemqulifierPosidentity?.stock_qty || 0;
    product.maximumsaleunitcount = positemqulifierPosidentity?.maximumsaleunitcount || 0;

    this.updateProductQuantityFromCart(product);

    if (!product.hasOwnProperty('quantity')) {
      product.itemCount = 1;
      product.quantity = 1;
    }
  }

  private getActivePosItemQualifiers(product: any): any[] {
    return product.hasOwnProperty('positemqualifiers')
      ? product.positemqualifiers.filter((element: any) => element.status === 'Active')
      : [];
  }

  private prepareQualifierData(product: any, positemqualifiers: any[], eventStoreId?: any): any {
    let allQualifierWisePriceArray: any = [];
    let regularpriceData: any = [];
    let breakpriceData: any = [];
    let posidentity: any = [];
    let actual_stock: number = 0;
    let maximumsaleunitcount: number = 0;

    positemqualifiers.forEach((element: any) => {
      const storeId = eventStoreId || +localStorage.getItem('storeId');

      regularpriceData = regularpriceData.concat(this.getPriceArray(product.item_regular_price, storeId, element.positemqulifier));
      breakpriceData = breakpriceData.concat(this.getPriceArray(product.item_break_price, storeId, element.positemqulifier, true));
      posidentity = posidentity.concat(this.getStockArray(product.posidentity, storeId, element.positemqulifier));
    });

    allQualifierWisePriceArray.sort((a: any, b: any) => a - b);

    return { allQualifierWisePriceArray, regularpriceData, breakpriceData, posidentity, actual_stock, maximumsaleunitcount };
  }

  private getPriceArray(priceArray: any[], storeId: number, positemqulifier: number, positivePrice: boolean = false): any[] {
    return priceArray ? priceArray.filter((value: any) => +value.store === storeId && +value.positemqulifier === positemqulifier && (positivePrice ? +value.price > 0 : true)) : [];
  }

  private getStockArray(stockArray: any[], storeId: number, positemqulifier: number): any[] {
    return stockArray ? stockArray.filter((value: any) => +value.store_id === storeId && +value.positemqulifier_id === positemqulifier) : [];
  }

  private getDefaultQualifier(positemqualifiers: any[]): any {
    const defaultQualifier = positemqualifiers.find((element: any) => element.isdefault === true);
    return defaultQualifier || positemqualifiers[0] || {};
  }

  private getPositemqulifierPosidentity(posidentity: any[], positemqualifier: number): any {
    return posidentity.find((element: any) => +element.positemqulifier_id === +positemqualifier) || {};
  }

  private updateProductQuantityFromCart(product: any): void {
    if (!('isAddonOf' in product) || product?.isAddonOf === null) {
      const cartDataInfo: any = this.cartService.getCartItemArray();
      const cartDataDetails: any = cartDataInfo.filter((x: any) => +x.item_id === +product.Item.id && +x.positemqualifier === +product.positemqualifier && !('isAddonOf' in x));

      if (cartDataDetails.length > 0) {
        product.itemCount = +cartDataDetails[0].quantity;
        product.quantity = +cartDataDetails[0].quantity;
      }
    }
  }
  // setPosItemQualifiers() method ends



  private setPriceData(product: any, collectionWisePriceArray?: any) {
    this.getPrice(product);
    collectionWisePriceArray.push({ price: product.price, regularPrice: product.regularPrice });

    collectionWisePriceArray.sort((a: any, b: any) => (a.regularPrice + a.price) - (b.regularPrice + b.price));
    product.collectionWisePriceArray = collectionWisePriceArray;
  }


  private setSellingRules(product: any, eventStoreId?: any) {
    let inStoreOnly = false;
    let notToShowOnline = false;
    let notToShowInventory = false;
    let notToShowBookingInventory = false;
    let booking = false;

    if (!product.hasOwnProperty('posidentity')) {
      return; // No further processing needed
    }

    const storeIdToCheck = eventStoreId || +localStorage.getItem('storeId');

    const sellingRule = product.posidentity.find((item: any) =>
      +item.store_id === +storeIdToCheck && +item.positemqulifier_id === +product.positemqualifier
    );

    if (!sellingRule) {
      return; // No matching selling rule found
    }

    inStoreOnly = sellingRule.in_store_only === true;
    notToShowOnline = sellingRule.not_to_show_online === true;
    notToShowInventory = sellingRule.not_to_show_inventory === true;
    notToShowBookingInventory = sellingRule.do_not_show_booking_inventory === true;
    booking = sellingRule.booking === true;

    product.inStoreOnly = inStoreOnly;
    product.notToShowOnline = notToShowOnline;
    product.notToShowInventory = notToShowInventory;
    product.notToShowBookingInventory = notToShowBookingInventory;
    product.booking = booking;
  }

  /**
   * setCollectionWisePrice()
   * @param product
   * @param eventStoreId
   */
  private setCollectionWisePrice(product: any, eventStoreId?: any) {
    if ('itemcollections' in product) {
      const collectionWisePriceArray: any = [];

      product.itemcollections = this.filterActiveItemCollections(product.itemcollections);

      product.itemcollections.forEach((element: any) => {
        const { regularpriceData, breakpriceData } = this.processItemCollection(element, eventStoreId);

        element.regularpriceData = regularpriceData;
        element.breakpriceData = breakpriceData;
        this.getPrice(element);

        collectionWisePriceArray.push({ price: element.price, regularPrice: element.regularPrice });
      });

      this.setPriceData(product, collectionWisePriceArray);
    }
  }

  private filterActiveItemCollections(itemcollections: any[]): any[] {
    return itemcollections.filter((o: any) => !o.hasOwnProperty('status') || (o.hasOwnProperty('status') && o.status.toLowerCase() == 'active'));
  }

  private processItemCollection(element: any, eventStoreId?: any): any {
    let regularpriceData: any = [];
    let breakpriceData: any = [];

    if (element.hasOwnProperty('positemqualifiers')) {
      element.positemqualifier = element.positemqualifiers[0];
    }

    if (element.hasOwnProperty('item_regular_price')) {
      const priceArray = this.filterItemRegularPrice(element.item_regular_price, eventStoreId);
      regularpriceData = priceArray;
      if (priceArray.length > 0) {
        element.positemqualifier = priceArray[0].positemqulifier;
      }
    }

    if (element.hasOwnProperty('item_break_price')) {
      const priceArray1 = this.filterItemBreakPrice(element.item_break_price, eventStoreId);
      breakpriceData = priceArray1;
    }

    return { positemqualifier: element.positemqualifier, regularpriceData, breakpriceData };
  }

  private filterItemRegularPrice(itemRegularPrice: any[], eventStoreId?: any): any[] {
    return itemRegularPrice.filter((o: any) => +o.store === +(eventStoreId || localStorage.getItem('storeId')));
  }

  private filterItemBreakPrice(itemBreakPrice: any[], eventStoreId?: any): any[] {
    return itemBreakPrice.filter((value: any) => +value.store === +(eventStoreId || localStorage.getItem('storeId')) && +value.quantityfrom === 1 && +value.price > 0);
  }


  // setCollectionWisePrice() ends here.

  private setUOMConversion(product: any, eventStoreId?: any) {
    let defaultStock: number = 0;
    if (product && product.hasOwnProperty('store_data')) {
      Object.entries(product.store_data).forEach(([key, value]) => {
        let itemValue: any = value
        if (itemValue.store_id == (eventStoreId ?? (eventStoreId || +localStorage.getItem('storeId')))) {
          defaultStock = itemValue.positemqulifier_default.stock_qty;
        }
      });
      product.defaultStock = defaultStock;
    }
  }

  claimRebate(url: any, type: string, obj: any, urlType: string): any {
    return this.dataApiService.claimRebate(url, type, obj, urlType).pipe(
      map(data => {
        return data;
      })
    )
  }
  getActiveEbateUrl(): any {
    return this.dataApiService.getActiveEbateUrl().pipe(
      map(data => {
        return data;
      })
    )
  }


  getSeoDetails(cmsSlugKey: string, cmsSlug: string, storeId?: string): any {
    return this.dataApiService.getSeoDetails(cmsSlugKey, cmsSlug, storeId).pipe(
      map(data => {
        return data;
      })
    )
  }


  getSitemapDetails(pageName: string, slugData?: any, SitemapObj?: any): any {
    return this.dataApiService.getSitemapDetails(pageName, slugData, SitemapObj).pipe(
      map(data => {
        return data;
      })
    )
  }

  createxmlsitemap(url: string, param?: any): any {
    return this.dataApiService.createxmlsitemap(url, param).pipe(
      map(data => {
        return data;
      })
    )
  }

  getExistSitemap(url: string, filename: string): any {
    return this.dataApiService.getExistSitemap(url, filename).pipe(
      map(data => {
        return data;
      })
    )
  }

  getTodayDate() {
    const currentDate = new Date();
    let fullyear = currentDate.getFullYear();
    let month: any = currentDate.getMonth() + 1;
    month = month > 9 ? month : '0' + month;
    let date: any = currentDate.getDate();
    date = date > 9 ? date : '0' + date;
    let todayDate = `${fullyear}-${month}-${date}`;
    return todayDate;
  }

  checkItemsInStock(itemsArr: any, booking_store?: number) {
    return this.dataApiService.checkItemsInStock(itemsArr, booking_store).pipe(
      map(data => {
        return data;
      })
    )
  }

  getGlobalScript() {
    return this.dataApiService.getGlobalScript().pipe(
      map(data => {
        return data;
      })
    )
  }

  getSliderDataByIds(requesIds: any): any {
    return this.dataApiService.getSliderDataByIds(requesIds).pipe(
      map(data => {
        return data;
      }),
    );
  }


  getImageData(requestData: any): Observable<any> {
    return this.dataApiService.getImageData(requestData);
  }

  getCartContainer(container_settings_id?: number,  isViwed_false_switched_true?: boolean) {
    return this.dataApiService.getCartContainerProducts(container_settings_id, isViwed_false_switched_true);
  }

  getOrderProducts(order_Id:any,old_order:any){
    return this.dataApiService.getOrderProducts(order_Id,old_order);
  }

  getSavedContainerList() {
    return this.dataApiService.getSavedContainerList();
  }

  getCustomerCartCount(){
    return this.dataApiService.getCustomerCartCount();
  }

  deleteItemFromCart(container_settings_id: number, customerorderlineitemid: number) {
    return this.dataApiService.deleteItemFromCart(container_settings_id, customerorderlineitemid);
  }

  deletePalletFromCart(container_settings_id: number, palletOrder: any, orderedit_delete:any ) {
    return this.dataApiService.deletePalletFromCart(container_settings_id, palletOrder, orderedit_delete);
  }

  deletePalletConfirmation(container_settings_id: number, palletOrder: string, orderedit_delete:any ) {
    return this.dataApiService.deletePalletConfirmation(container_settings_id, palletOrder, orderedit_delete);
  }

  saveCartContainer(containerId: number, containerName: string) {
    return this.dataApiService.saveCartContainer(containerId, containerName);
  }

  clearCartOrder(containerId: number) {
    return this.dataApiService.clearCartOrder(containerId);
  }

  createNewContainer(data:any){
    return this.dataApiService.createNewContainer(data);
  }

  addColorInCart(requestData: any){
    return this.dataApiService.addColorInCart(requestData);
  }

  cartCheckout(data:any){
    return this.dataApiService.cartCheckout(data);
  }

  //accountSettings
  /**
  * Method to put account settings on user ID.
  * @param userid id of existing user;
  * @returns Observable of my profile response.
  */
  putAccountSettings(userId: any, bodyobject: any): Observable<any> {
    return this.dataApiService.putAccountSettings(userId, bodyobject).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
// General Enquiry Post data
general_enquiry_postData(postData: any): Observable<any> {
  return this.dataApiService.general_enquiry_postData(postData).pipe(
    map((data: any) => {
      return data;
    })
  );
}

  //addressBook
  /**
   * Method to post  on user ID.
   * @param userid id of existing user;
   * @returns Observable of my profile response.
   */
  postAdressbook(userId: any, bodyobject: any): Observable<any> {
    return this.dataApiService.postAdressbook(userId, bodyobject).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  //addressBookForm
  /**
   * Method to get list
   * @param requestData Request data for api.
   * @returns Observable of httpclient.
   */
  getAddress(userId: any): any {
    return this.dataApiService.getAddress(userId).pipe(
      map(data => {
        return data;
      }),
    );
  }

  getEditAddress(party_id: any, itemId: any): any {
    return this.dataApiService.getEditAddress(party_id, itemId).pipe(
      map(data => {
        return data;
      })
    )
  }
  //deleteAddress
  /**
   * Method to delete address
   * @returns Observable of httpclient.
   */
  deleteAddress(itemid: any): any {
    return this.dataApiService.deleteAddress(itemid).pipe(
      map(data => {
        return data;
      }),
    );
  }

  updateAddress(party_Id: any, itemId: any, requestData: any): any {
    return this.dataApiService.updateAddress(party_Id, itemId, requestData).pipe(
      map(data => {
        return data;
      })
    )
  }


  //For Dialog Handeling

  showDialog(data: { key: string, data?: any }, onResponse: (res: any) => void) {
    let index = DIALOG_REF.findIndex((e: any) => e.key === data.key);
    if (index === -1) return;
    this.dialog
      .open(DIALOG_REF[index].component, {
        width: data?.data?.width || DIALOG_REF[index].width,
        height: DIALOG_REF[index].height,
        data: { ...data.data },
        panelClass: DIALOG_REF[index].panelClass,
        autoFocus: false,
        disableClose: true
      })
      .afterClosed()
      .subscribe(onResponse);
  }



  getCurrentUrlWithoutParams(): any {
    const urlTree = this.router.parseUrl(this.router.url);
    const primaryRoute = urlTree.root.children['primary'];
    return primaryRoute;
  }

  //Container Detsials

  getContainerDetails(id?:any) {
    if(id){
      return this.dataApiService.getContainerDetails(id).pipe(
        map(data => {
          return data;
        })
      )
    }else{
      return this.dataApiService.getContainerDetails().pipe(
        map(data => {
          return data;
        })
      )
    }

  }

  changeContainerDetails(id:any, obj:any) {
    return this.dataApiService.changeContainerDetails(id,obj).pipe(
      map(data => {
        return data;
      })
    )
  }

  getCartCount() {
    this.getCustomerCartCount().subscribe((res: any) => {
      if (res) {
        this.setCartCountValue(res.cart_count);
        localStorage.setItem('cartCount', res.cart_count);
      }
    });
  }

  private isLoggedInSubject = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem("isLoggedin")));
  public isLoggedIn$ = this.isLoggedInSubject.asObservable();
  setLoginValue(value: boolean) {
    this.isLoggedInSubject.next(value);
  }


  private cartCountSubject = new BehaviorSubject<number>(JSON.parse(localStorage.getItem("cartCount")));
  public cartCount$ = this.cartCountSubject.asObservable();
  setCartCountValue(value: number) {
    this.cartCountSubject.next(value);
  }

  saveRetailFreightValues(obj:any){
    return this.dataApiService.saveRetailFreightValues(obj).pipe(
      map(data => {
        return data;
      })
    )
  }
  getRetailFreightValues(){
    return this.dataApiService.getRetailFreightValues().pipe(
      map(data => {
        return data;
      })
    )
  }

  placeOrder(requestData:any){
    return this.dataApiService.placeOrder(requestData);
  }

  productQtyChange(requestData:any){
    return this.dataApiService.productQtyChange(requestData);
  }

  getMyOrdersList() {
    return this.dataApiService.getMyOrdersList();
  }

  getOrderDetails(data: any){
    return this.dataApiService.getOrderDetails(data);
  }

  getProductInCart(pallet_type: any, item_identifier:any, specification_name:any, batchcolorabriviation:any){
    return this.dataApiService.getProductInCart(pallet_type,item_identifier,specification_name,batchcolorabriviation);
  }

  copyPalletCart(orderId:any, palletOrder:any,pallet:any,usercontainerSettings_id?:number){
    return this.dataApiService.copyPalletCart(orderId,palletOrder,pallet,usercontainerSettings_id);
  }
}
